import mixpanel, { Dict } from "mixpanel-browser";

/**
 * Mixpanel Super Properties we include on every calls
 * TODO: "Company Name" and "orgId" are legacy variables from Dreambig, remove once we fully transition out of dreambig.
 * */
export const MIXPANEL_SUPER_PROPERTIES = [
  "Organization Name",
  "Organization Id",
  "Company Name",
  "orgId",
];

/**
 * The Project Token for the Mixpanel API.
 */
const mixpanelProjectToken =
  process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN ||
  "2d45501068d4674285ac75095647ea7a";

/**
 * Methods we will include in the mixpanel hook.
 */
export interface MixpanelHookMethods {
  init(): undefined;
  identify(id: string): any;
  track(eventName: string, properties?: Dict | undefined): void;
  register(properties: Dict): void;
  reset(): void;
  getSuperProperties(superProperties: string[]): {};
}

/**
 * Custom hook that handles analytics for Mixpanel.
 */
const useMixpanel = (): MixpanelHookMethods => {
  /**
   * Safeguard just incase the library wasn't initialized.
   */

  if (process?.browser) {
    mixpanel.init(mixpanelProjectToken);
  }

  return {
    //********** OFFICIAL MIXPANEL METHODS **********//

    /**
     * Initializes a new instance of the Mixpanel tracking object.
     * Reference: https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelinit
     */
    init() {
      return mixpanel.init(mixpanelProjectToken);
    },

    /**
     * Identify a user with a unique ID to track user activity across devices, tie a user to their events, and create a user profile.
     * Reference: https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelidentify
     */
    identify(id: string) {
      return mixpanel.identify(id);
    },

    /**
     * Track a specific event the user is currently initiating.
     * Reference: https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelidentify
     */
    track(eventName: string, properties?: Dict) {
      return mixpanel.track(eventName, properties);
    },

    /**
     * Register a set of super properties, which are included with all events. This will overwrite previous super property values.
     * Reference: https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelregister
     */
    register(properties: Dict) {
      return mixpanel.register(properties);
    },

    /**
     * Clears super properties and generates a new random distinct_id for this instance. Useful for clearing data when a user logs out.
     * Reference: https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelreset
     */
    reset() {
      return mixpanel.reset();
    },

    //********** CUSTOM METHODS **********//

    /**
     * Return all super properties in the current mixpanel instance based on array of properties passed in.
     * Mixpanel doesn't provide us a way to get all super properties of the instance so this was made to get those values
     * based on the values we pass to the function.
     */
    getSuperProperties(superProperties: string[]) {
      return superProperties.reduce(
        (previousProperties, currentPropertyName) => {
          // Check if mixpanel super property exists in the current instance
          const superProperty = mixpanel.get_property(currentPropertyName);

          const currentProperty = superProperty
            ? { [currentPropertyName]: superProperty }
            : {};

          return { ...previousProperties, ...currentProperty };
        },
        {}
      );
    },
  };
};

export default useMixpanel;
