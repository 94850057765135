import React from "react";
import { DefaultThemeProvider } from "../../hooks";
import { BreakpointProvider } from "../../hooks/use-breakpoint";

const DefaultView = ({
  minHeight,
  children,
}: {
  minHeight?: number;
  children: React.ReactChild;
}) => {
  return (
    <DefaultThemeProvider>
      <BreakpointProvider>
        <div
          style={{
            minHeight,
          }}
        >
          {children}
        </div>
      </BreakpointProvider>
    </DefaultThemeProvider>
  );
};

export default DefaultView;
