//************************************************************************ /
// DECORATED IMAGE
// Image that can be styled with optional banner and caption parameters.
// In Communities package, defaults to using colors from themeProvider. These will be overridden if props are passed in.
// TODO: Consider replacing <img> with next/Image to allow for lazy loading.
//************************************************************************ /
import React, { useState } from "react";
import getIcon from "../../../utils/getIcon";
import cotributeDefaults from "@dreambigger/shared/src/assets/cotribute-defaults";
import truncate from "truncate";

export interface DecoratedImageProps {
  src: string;
  alt: string;
  imgBrightness?: number;
  title?: string;
  titleClass?: string;
  titleStyle?: any;
  bannerEnabled?: boolean;
  bannerText?: string;
  bannerTextColor?: string; // If passed in, overrides defaults provided by themeProvider
  bannerBkdColor?: string; // If passed in, overrides defaults provided by themeProvider
  cornerButtonEnabled?: boolean;
  cornerButtonFunction?: React.MouseEventHandler;
  cornerButtonText?: string;
  cornerButtonTextExpanded?: string;
  cornerButtonIcon?: string;
  caption?: string;
  captionClass?: string;
  captionStyle?: any;
  className?: string;
  style?: any;
  onClick?: React.MouseEventHandler;
  pinned?: boolean;
}

const DecoratedImage = ({
  src,
  alt,
  imgBrightness,
  title,
  titleClass = "white",
  bannerEnabled = false,
  bannerText,
  bannerTextColor = "white",
  bannerBkdColor,
  cornerButtonEnabled,
  cornerButtonFunction,
  cornerButtonText,
  cornerButtonTextExpanded,
  cornerButtonIcon,
  caption,
  captionClass = "",
  className = "",
  onClick,
  pinned = false,
  style,
}: DecoratedImageProps) => {
  const [cornerButtonHovered, setCornerButtonHovered] = useState(false);

  return (
    <div
      className={className}
      style={{ position: "relative", overflow: "hidden", ...style }}
      onClick={onClick}
    >
      {/* PINNED ICON - Will Overlay Image */}
      {pinned && (
        <div className="z-1">
          <div
            className="w-11 h-11 absolute db overflow-hidden"
            style={{
              backgroundColor: "#fa9f00",
              transform: "rotate(45deg)",
              top: "-50px",
              left: "-50px",
            }}
          ></div>
          <img
            className="absolute"
            style={{
              top: "6px",
              left: "6px",
              height: "22px",
              width: "22px",
            }}
            src={cotributeDefaults.pinnedIcon}
          />
        </div>
      )}

      {/* TITLE - will overlay image */}
      {title && (
        <p
          className={`${titleClass} usn mb-0 tc z-1 absolute lh-3 w-90`}
          style={{
            left: "50%",
            top: "50%",
            transform: "translateX(-50%) translateY(-50%)",
            overflowWrap: "break-word",
          }}
        >
          {truncate(title, 138)}
        </p>
      )}

      {/* BANNER - If enabled, will overlay image */}
      {bannerEnabled && (
        <div
          className="z-1 absolute flex"
          style={{
            top: "85%",
            transform: "translateY(-85%)",
            boxShadow: "-30px 4px 8px black",
          }}
        >
          {/* Banner Text --- bannerBkdColor and bannerTxtColor override defined style if present*/}
          <div
            className={`${!bannerBkdColor && `bg-primary`} ${
              bannerTextColor && "textPrimary"
            } ph-3 pv-1 f-4 fwb ttu`}
            style={{
              backgroundColor: bannerBkdColor,
              color: bannerTextColor,
              minHeight: "20px",
            }}
          >
            <span className="uns f-3" style={{ letterSpacing: "0.25px" }}>
              {bannerText}
            </span>
          </div>
          {bannerBkdColor ? (
            // Banner Corners with defined bannerBkdColors
            <div className="flex flex-column">
              <div
                style={{
                  height: "50%",
                  width: "15px",
                  background: `linear-gradient(135deg, ${bannerBkdColor} 50%, transparent 50%)`,
                }}
              />
              <div
                style={{
                  height: "50%",
                  width: "15px",
                  background: `linear-gradient(225deg, transparent 50%, ${bannerBkdColor} 50%)`,
                }}
              />
            </div>
          ) : (
            // Banner Corners without bannerBkdColors (default) -- utilizes class from themeProvider
            <div className="flex flex-column">
              <div
                className="bg-transparent-corner-bottom"
                style={{
                  height: "50%",
                  width: "15px",
                }}
              />
              <div
                className="bg-transparent-corner-top"
                style={{
                  height: "50%",
                  width: "15px",
                }}
              />
            </div>
          )}
        </div>
      )}

      {/* CORNER BUTTON - If enabled, will overlay both image and banner (if banner is also enabled) */}
      {cornerButtonEnabled && cornerButtonFunction && (
        <div
          className="gray-9 pointer"
          onClick={cornerButtonFunction}
          onMouseEnter={() => {
            cornerButtonTextExpanded && setCornerButtonHovered(true);
          }}
          onMouseLeave={() => setCornerButtonHovered(false)}
        >
          <div
            className="flex items-end absolute z-2 pa-1"
            style={{
              top: "100%",
              right: 0,
              transform: "translateY(-100%)",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              width: "auto",
              transition: "2s",
            }}
          >
            {/* Icon (if passed in) */}
            {cornerButtonIcon && (
              <div className="flex mr-1">{getIcon(cornerButtonIcon, 2)}</div>
            )}
            {/* Text - Display expanded text if the button is hovered */}
            <p className="mb-0 f-4">
              {cornerButtonHovered
                ? cornerButtonTextExpanded
                : cornerButtonText}
            </p>
          </div>
        </div>
      )}

      {/* IMAGE - Eventually replace with next/image */}
      <img
        alt={alt}
        src={src}
        className="w-100 h-auto z-0"
        style={{
          filter: `brightness(${imgBrightness}%)`,
        }}
      />
      {/* IMAGE CAPTION - will appear below image */}
      {caption && (
        <p
          className={captionClass}
          style={{ overflowWrap: "break-word", padding: "12px 24px" }}
        >
          {truncate(caption, 138)}
        </p>
      )}
    </div>
  );
};

export default DecoratedImage;
