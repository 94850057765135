//************************************************************************ /
// APPCUES BUTTON
// * Displays a fixed, circular button at the bottom right of the screen for triggering appcues flows.
// * Unlike normal appcues, which are triggered automatically by the url, appcues triggered by this button
//   are done manually, using the .show() method.
// * WARNING - There is no fallback if an appcue fails to load. Make sure that appcues are configured at the appcue dashboard
//   to display on the page this button is placed on.
//************************************************************************ /

import React, { memo } from "react";
import { Typography } from "../../";
import { QuestionOutlined } from "@ant-design/icons";
import { useAppcues } from "@dreambigger/shared/src/hooks";

const { Text } = Typography;

type appcuesButtonProps = {
  background?: string;
  color?: string;
  icon?: React.ReactElement;
  text?: string;
  size?: string;
  additionalClassName?: string;
  appcuesFlow: string;
  className?: string;
  bottom?: string;
  right?: string;
};

// Loads a "Help" Button by default. Change by submitting different props.
const AppcuesButton = ({
  background = "black",
  color = "white",
  icon = <QuestionOutlined style={{ fontSize: "40px" }} />,
  text = "HELP",
  size = "80px",
  bottom = "15px",
  right = "15px",
  additionalClassName,
  appcuesFlow,
}: appcuesButtonProps) => {
  const appcues = useAppcues();
  const handleClick = () => {
    appcues.show(appcuesFlow);
  };

  return (
    <div
      style={{
        bottom: bottom,
        right: right,
        width: size,
        height: size,
        background: background,
        color: color,
      }}
      className={`${additionalClassName} flex flex-column fixed z-99 br-100 pointer justify-center subtleGrow`}
      onClick={handleClick}
    >
      {icon}
      <Text className="tc usn color-inherit f-4">{text}</Text>
    </div>
  );
};

export default memo(AppcuesButton);
