import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";
import { useRouter } from "next/router";

class Segment {
  analytics: Analytics | undefined;
  private WRITE_KEY: string = process.env
    .NEXT_PUBLIC_SEGMENT_WRITE_KEY as string;

  async loadAnalytics() {
    if (this.analytics) return;
    const [response] = await AnalyticsBrowser.load({
      writeKey: this.WRITE_KEY,
    });
    this.analytics = response;
  }
}

type AnalyticAction =
  | "Button Click"
  | "Link Click"
  | "Payment"
  | "Authentication"
  | "App State Change";

interface TrackOptions {
  action: string;
  label: string;
  properties?: Record<string, any> | undefined;
}

interface IdentifyOptions {
  userId?: string;
  traits?: Record<string, any | undefined>;
}

interface GroupOptions {
  groupId: string;
  traits?: Record<string, any | undefined>;
}

interface PageOptions {
  name?: string;
  properties?: Record<string, string | undefined>;
}

const segment = new Segment();

export interface SegmentHookMethods {
  track({ action, label, properties }: TrackOptions): Promise<void>;
  group({ groupId, traits }: GroupOptions): Promise<void>;
  identify({ userId, traits }: IdentifyOptions): Promise<void>;
  page({ name, properties }: PageOptions): Promise<void>;
  loadAnalytics(): Promise<void>;
  reset(): Promise<void>;
}

export const useSegment = (): SegmentHookMethods => {
  const router = useRouter();
  return {
    //use to track actions taken by a user
    //category, locale and router query params are added automatically
    //read more - https://segment.com/docs/connections/spec/track/
    async track({ action, label, properties = {} }: TrackOptions) {
      await segment.loadAnalytics();
      segment.analytics?.track(label, {
        ...router.query,
        locale: router.locale,
        action: action,
        category: router.pathname,
        ...properties,
      });
    },

    //use to associate an identified user with a company, etc.
    //read more - https://segment.com/docs/connections/spec/group/
    async group({ groupId, traits = {} }: GroupOptions) {
      await segment.loadAnalytics();
      segment.analytics?.group(groupId, traits);
    },

    //use to identify a user and add traits
    //traits can be tracked even before a user id associated
    //traits from previous calls are resent with each identify call
    //read more - https://segment.com/docs/connections/spec/identify/
    async identify({ userId, traits = {} }: IdentifyOptions) {
      await segment.loadAnalytics();
      userId
        ? segment.analytics?.identify(userId, traits)
        : segment.analytics?.identify(traits);
    },

    //use to track page load / navigation related actions
    //category and router query params are added automatically
    //read more - https://segment.com/docs/connections/spec/page/
    async page({ name, properties = {} }: PageOptions) {
      await segment.loadAnalytics();
      const enhancedProperties = {
        ...router.query,
        ...properties,
      };
      name
        ? segment.analytics?.page(router.pathname, name, enhancedProperties)
        : segment.analytics?.page(enhancedProperties);
    },

    // Resets the id, including anonymousId, and clears traits for the currently identified user.
    // read more - https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#reset-or-logout
    async reset() {
      await segment.loadAnalytics();
      segment.analytics?.reset();
    },

    //loads the segment library
    async loadAnalytics() {
      await segment.loadAnalytics();
    },
  };
};
