import { useEffect, useState, useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import {
  REFRESH_TOKEN_PARAM,
  keyByLoginModeHash,
} from "@dreambigger/shared/src/constants/auth";
import {
  extractBrowserQueryParam,
  removeBrowserQueryParam,
} from "@dreambigger/shared/src/utils";
import getFingerprint from "@dreambigger/shared/src/utils/getFingerprint";
import { useAuthToken, useSegment } from "@dreambigger/shared/src/hooks";
import { LocalStorage } from "@dreambigger/shared/src/utils";
import { AuthApi, api } from "@dreambigger/shared/src/api/acquire";
import useAuthHandler from "@dreambigger/shared/src/hooks/use-auth-handler";
const authApi = new AuthApi(api);

export default function useMagicLink(
  financialInstitutionId?: string,
  trackingReference?: Record<string, string | undefined>
) {
  const router = useRouter();
  const tokenHelper = useAuthToken(financialInstitutionId);
  const { successHandler } = useAuthHandler(
    financialInstitutionId,
    trackingReference
  );
  const segment = useSegment();

  const localStorage = useMemo(
    () => new LocalStorage(financialInstitutionId),
    [financialInstitutionId]
  );
  const [refreshToken, setRefreshToken] =
    useState<string | null | undefined>(null);
  const [needsOtpVerification, setNeedsOtpVerification] = useState(false);
  const [userId, setUserId] = useState("");
  const [loginId, setLoginId] = useState("");
  const [loginType, setLoginType] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    //get the refresh token from the url and remove it from the url
    const refreshTokenQueryParam =
      extractBrowserQueryParam(REFRESH_TOKEN_PARAM);

    if (refreshTokenQueryParam && refreshToken === null) {
      setRefreshToken(refreshTokenQueryParam);
      removeBrowserQueryParam(REFRESH_TOKEN_PARAM, router);
    }
  }, [router]);

  const verifyRefreshToken = useCallback(
    (token: string) => {
      // remove previous JWT and loginId if it exists
      tokenHelper.removeJwtToken();
      localStorage.removeItem(keyByLoginModeHash.email);
      localStorage.removeItem(keyByLoginModeHash.sms);

      return getFingerprint()
        .then((visitorId) =>
          authApi.verifyMagicLink({
            userType: "acquire",
            deviceFingerprint: visitorId,
            token: token || "",
          })
        )
        .then(({ data }) => {
          // refresh token verified
          if ("jwt" in data) {
            successHandler(data.jwt, "Magic Link Verified");
            return true;
          }

          //needs OTP verification
          if (data.needsOtpVerification) {
            setUserId(data.user.id);
            setLoginId(data.user.login.id);
            setLoginType(data.user.login.type);
            setNeedsOtpVerification(true);
            return false;
          }

          // invalid refresh token
          return true;
        })
        .catch((error) => {
          // most likely the URL is ill-formed resulting in a 422
          segment.track({
            action: "Authentication",
            label: "Refresh Token Verification Failed",
            properties: {
              error: error.message,
              ...trackingReference,
            },
          });
          return true;
        })
        .finally(() => {
          // clear refresh token from state after verification
          setRefreshToken(undefined);
        });
    },
    [successHandler, segment, localStorage, tokenHelper, trackingReference]
  );

  const buildAuthUrl = useCallback(
    (queryParams?: Record<string, string | undefined>) => {
      const authParams = loginId
        ? {
            otpId: loginId,
            ...queryParams,
          }
        : queryParams;

      // Remove magic link token from query params.
      const { [REFRESH_TOKEN_PARAM]: _, ...nonAuthQueryParams } = router.query;
      return {
        query: {
          ...nonAuthQueryParams,
          ...authParams,
        },
      };
    },
    [loginId, router.query]
  );

  const resetNeedsOtpVerification = useCallback(() => {
    setNeedsOtpVerification(false);
  }, []);

  return {
    refreshToken,
    userId,
    loginId,
    loginType,
    needsOtpVerification,
    verifyRefreshToken,
    buildAuthUrl,
    resetNeedsOtpVerification,
  };
}
