import axios, { AxiosInstance } from "axios";
import TokenHelper from "../utils/tokenHelper";

export const createApi = (apiUrl: string, cotributeClientId: string) => {
  return axios.create({
    baseURL: apiUrl,
    responseType: "json",
    headers: {
      "X-Cotribute-Client-Id": cotributeClientId,
    },
  });
};

export const baseHeaders = {
  Accept: "application/vnd.cotribute.v2+json",
  "Content-Type": "application/json",
};

export const fetcher = (
  api: AxiosInstance,
  tokenHelper: TokenHelper,
  url: string
) => {
  const headers: Record<string, any> = {};
  const token = tokenHelper.getJwtToken();

  if (token && !tokenHelper.jwtIsExpired()) {
    headers["Authorization"] = tokenHelper.formatAuthHeader(token);
  }

  return api.get(url, { headers }).then((res) => res.data);
};

export const fetcherWithToken = (
  api: AxiosInstance,
  tokenHelper: TokenHelper,
  url: string
) => {
  const token = tokenHelper.getJwtToken();

  return api
    .get(url, {
      headers: {
        ...baseHeaders,
        Authorization: tokenHelper.formatAuthHeader(token),
      },
    })
    .then((res) => res.data);
};

export const fetcherWithRefreshToken = (
  api: AxiosInstance,
  tokenHelper: TokenHelper,
  url: string
) => {
  const token = tokenHelper.getRefreshToken();

  return api
    .get(url, {
      headers: {
        ...baseHeaders,
        Authorization: tokenHelper.formatAuthHeader(token),
      },
    })
    .then((res) => res.data);
};
