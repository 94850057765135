import { NextRouter } from "next/router";
export type WeserveSortOptions = "id" | "created_at" | "start_at" | "title";
export type WeserveSortDirection = "desc" | "asc";

export interface WeserveQueryParams {
  offset: string;
  limit: string;
  sort: WeserveSortOptions;
  direction: WeserveSortDirection;
  [key: string]: string;
}

/**
 * Generates query params to append to an API url for all weserve endpoints that paginate.
 */
export const generateWeserveQueryParams = (params: WeserveQueryParams) => {
  const queryParams = new URLSearchParams(params);
  return queryParams.toString();
};

/**
 * Generate a API Url with query params appended to the end of the url.
 */
export const generateApiUrlWithQueryParams = (
  baseUrl: string,
  queryParams?: string
) => `${baseUrl}${queryParams ? `?${queryParams}` : ""}`;

/**
 * Extract browser query param.
 */
export const extractBrowserQueryParam = (key: string) => {
  if (!process?.browser) {
    return;
  }
  const urlSearchParams = new URLSearchParams(window.location.search || "");
  const queryParams = Object.fromEntries(urlSearchParams.entries());
  return queryParams[key];
};

/**
 * Remove browser query param.
 */
export const removeBrowserQueryParam = (key: string, router: NextRouter) => {
  if (!process?.browser) {
    return;
  }
  const urlSearchParams = new URLSearchParams(window.location.search || "");
  const queryParams = Object.fromEntries(urlSearchParams.entries());
  const newParams = { ...queryParams };
  delete newParams[key];

  let queryString = "";
  if (Object.keys(newParams).length > 0) {
    queryString =
      "?" +
      Object.keys(newParams)
        .map((key) => key + "=" + newParams[key])
        .join("&");
  }
  const pathStr = `${router.asPath.split("?")[0]}${
    queryString ? queryString : ""
  }`;
  // Replace the query string with everything except the hash.
  // NOTE: Had to make shallow false otherwise a page that has not been
  // statically generated before breaks when authing in with a hash.
  router.replace(pathStr, undefined, { shallow: false });
};
