//************************************************************************************* /
// POPOVER INFO ICON
// Displays a info icon that opens a popover with information when hovered or clicked.
//************************************************************************************* /

import { useState } from "react";
import { InfoCircleOutlined } from "../../../icons/info";
import { Popover } from "../../atoms";

type PopoverInfoIconProps = {
  className?: string;
  style?: React.CSSProperties;
  popoverPlacement?:
    | "topLeft"
    | "top"
    | "topRight"
    | "left"
    | "leftBottom"
    | "rightTop"
    | "right"
    | "rightBottom"
    | "bottomLeft"
    | "bottom"
    | "bottomRight";
  popoverTitle?: React.ReactNode;
  popoverContent: React.ReactNode;
  overlayClassName?: string;
  overlayStyle?: React.CSSProperties;
  overlayInnerStyle?: React.CSSProperties;
  disableHoverEffect?: boolean;
  arrowPointAtCenter?: boolean;
};

const PopoverInfoIcon = ({
  className,
  style,
  popoverPlacement,
  popoverTitle,
  popoverContent,
  overlayClassName,
  overlayStyle,
  overlayInnerStyle,
  disableHoverEffect,
  arrowPointAtCenter = true,
}: PopoverInfoIconProps) => {
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleHoverChange = (visible: boolean) => {
    setHovered(visible);
    setClicked(false);
  };

  const handleClickChange = (visible: boolean) => {
    setHovered(visible);
    setClicked(!clicked); // Toggle open and closed.
  };

  return (
    <>
      {/* Top <Popover> displays on hover (normal functionality). */}
      <Popover
        placement={popoverPlacement || "bottomLeft"}
        title={popoverTitle}
        content={popoverContent}
        mouseEnterDelay={0}
        mouseLeaveDelay={0}
        trigger="hover"
        visible={disableHoverEffect ? false : hovered}
        onVisibleChange={handleHoverChange}
        overlayClassName={overlayClassName}
        overlayStyle={overlayStyle}
        overlayInnerStyle={overlayInnerStyle}
        arrowPointAtCenter={arrowPointAtCenter}
      >
        {/* Second <Popover> displays on click, which only matters for mobile use.*/}
        <Popover
          placement={popoverPlacement || "bottomLeft"}
          title={popoverTitle}
          content={popoverContent}
          trigger="click"
          visible={clicked}
          onVisibleChange={handleClickChange}
          overlayClassName={overlayClassName}
          overlayStyle={overlayStyle}
          overlayInnerStyle={overlayInnerStyle}
          arrowPointAtCenter={arrowPointAtCenter}
        >
          <div className={className} style={style}>
            <InfoCircleOutlined className={className} style={style} />
          </div>
        </Popover>
      </Popover>
    </>
  );
};

export default PopoverInfoIcon;
