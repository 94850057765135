//************************************************************************ /
// INPUT UPDATE
// Input field that includes buttons for saving an updated input value.
// Automatically is focused when mounted.
//************************************************************************ /
import Input from "../../atoms/input/input";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DownOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  ReactElement,
  useState,
  useEffect,
  useRef,
  useMemo,
  CSSProperties,
} from "react";
import { Select } from "../../atoms";

export interface InputUpdateProps {
  className?: string;
  style?: CSSProperties;
  initialValue?: string;
  placeHolder?: string;
  onSave: any;
  onCancel: any;
  onDelete?: any;
  confirmButton?: ReactElement;
  cancelButton?: ReactElement;
  prefixOptions?: string[];
  maxPrefixLength?: number;
}

const InputUpdate = ({
  className,
  style,
  initialValue,
  placeHolder,
  onSave,
  onCancel,
  onDelete,
  confirmButton,
  cancelButton,
  prefixOptions,
  maxPrefixLength = 200,
}: InputUpdateProps) => {
  // * Format the initial value to remove the prefix if it exists.
  const formatInitialValue = () => {
    if (initialValue && Array.isArray(prefixOptions)) {
      const matchingPrefix = prefixOptions.find((prefix) =>
        initialValue.startsWith(prefix)
      );
      if (matchingPrefix) {
        return initialValue.slice(matchingPrefix.length).trim();
      }
    }
    return initialValue || "";
  };

  // State Variables
  const [inputValue, setInputValue] = useState(formatInitialValue());
  const [prefixValue, setPrefixValue] = useState(
    prefixOptions ? prefixOptions[0] : ""
  );

  const inputRef = useRef<any>(null); // Any to avoid type errors with focus when utilizing antd input.
  useEffect(() => {
    // Focus the input element when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSave = () => {
    onSave(prefixValue + inputValue);
    onCancel();
  };

  // Calculate the max length of the prefix options to set the width of the select dropdown.
  const prefixLength = useMemo(() => {
    if (!prefixOptions) return 0;
    const largestOptionLength =
      Math.max(...prefixOptions.map((option) => option.length)) * 8; // * fontsize
    return largestOptionLength < maxPrefixLength
      ? largestOptionLength
      : maxPrefixLength;
  }, [prefixOptions]);

  return (
    <div className={className} style={style}>
      <div className="flex">
        {/* If prefix options are provided, a select dropdown will be rendered with the options provided. */}
        {prefixOptions && (
          <Select
            bordered={false}
            className="primary"
            style={{ width: prefixLength + 30 }}
            options={prefixOptions.map((option) => ({
              value: option,
              label: option,
            }))}
            onChange={(value) => setPrefixValue(value)}
            value={prefixValue}
            suffixIcon={<DownOutlined className="primary" />}
          />
        )}
        <Input
          ref={inputRef}
          className="bg-gray-4"
          bordered={false}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeHolder}
          onSubmit={() => onSave(inputValue)}
          value={inputValue}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSave();
            } else if (e.key === "Escape") {
              onCancel();
            }
          }}
        />
        <button
          className="bn bg-transparent pa-0 ml-2 pointer"
          onClick={handleSave}
        >
          {confirmButton || <CheckCircleFilled className="primary" />}
        </button>
        <button
          className="bn bg-transparent pa-0 ml-2 pointer"
          onClick={() => onCancel(inputValue)}
        >
          {cancelButton || <CloseCircleFilled className="warning" />}
        </button>
        {onDelete && (
          <button
            type={"button"}
            className="bg-transparent bn pointer hlight-primary"
            onClick={() => onDelete()}
          >
            <DeleteOutlined className="f-4 sm_f-6" />
          </button>
        )}
      </div>
    </div>
  );
};

export default InputUpdate;
