import { AxiosInstance } from "axios";
import TokenHelper from "../utils/tokenHelper";
export const basePath = "/users";

// All the paths for this api domain.
export const userPaths = {
  getMe: () => "/handshake",
};

class UserApi {
  api: AxiosInstance;
  tokenHelper: TokenHelper;

  constructor(api: AxiosInstance, tokenHelper: TokenHelper) {
    this.api = api;
    this.tokenHelper = tokenHelper;
  }

  // Get "me".
  // GET /handshake
  getMe = () => {
    const token = this.tokenHelper.getJwtToken();

    return this.api.get(userPaths.getMe(), {
      headers: {
        Authorization: this.tokenHelper.formatAuthHeader(token),
      },
    });
  };
}

export default UserApi;
