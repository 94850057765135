import { createUseStyles } from "react-jss";
import { pSBC } from "../lighten-darken-color";

export interface DefaultThemeStyles {
  colors?: Partial<DefaultColors>;
}

type HexColor = string;
export interface DefaultColors {
  primary: HexColor;
  textPrimary: HexColor;
  textSecondary: HexColor;
  ctaBackground: HexColor;
}

// Used as a fallback when no colors are passed.
const defaultColors: DefaultColors = {
  primary: "#52c1b2",
  textPrimary: "#262626",
  textSecondary: "#8c8c8c",
  ctaBackground: "#52c1b2",
};

export const useDefaultStyles = createUseStyles<
  "@global",
  {},
  Partial<DefaultThemeStyles>
>(
  ({ colors }) => {
    const {
      primary: primaryColor = defaultColors.primary,
      textPrimary: textPrimaryColor = defaultColors.textPrimary,
      textSecondary: textSecondaryColor = defaultColors.textSecondary,
      ctaBackground: ctaBackgroundColor = defaultColors.ctaBackground,
    } = colors || defaultColors;

    const lighterPrimary = pSBC(+0.5, primaryColor);
    const lighterPrimary4 = pSBC(+0.85, primaryColor); // Leaving Primary 2-3 available for now.
    const darkerPrimary = pSBC(-0.6, primaryColor);
    const warning = "#cc0000";
    const success = "#52c1b2";
    const lighterWarning = pSBC(+0.5, warning);
    const lighterWarning4 = pSBC(+0.85, warning); // Leaving Warning 2-3 available for now.

    return {
      "@global": {
        "#nprogress .bar": {
          background: primaryColor + " !important",
        },
        "#nprogress .peg": {
          boxShadow: `0 0 10px #29d, 0 0 5px ${primaryColor} !important`,
        },
        ".primary": {
          color: primaryColor + " !important",
        },
        ".cta": {
          color: ctaBackgroundColor + " !important",
        },
        ".darkPrimary": {
          color: darkerPrimary + " !important",
        },
        ".warning": {
          color: warning + " !important",
        },
        ".success": {
          color: success + " !important",
        },
        ".bg-primary": {
          background: primaryColor + " !important",
        },
        ".bg-primary-light": {
          background: lighterPrimary + " !important",
        },
        ".bg-primary-light-4": {
          background: lighterPrimary4 + " !important",
        },
        ".bg-warning": {
          background: warning + " !important",
        },
        ".bg-warning-light-1": {
          background: lighterWarning + " !important",
        },
        ".bg-warning-light-4": {
          background: lighterWarning4 + " !important",
        },
        ".bg-white": {
          background: "white !important",
        },
        ".b-primary": {
          borderColor: primaryColor + " !important",
        },
        ".b-lightPrimary": {
          borderColor: lighterPrimary + " !important",
        },
        ".b-darkPrimary": {
          borderColor: darkerPrimary + " !important",
        },
        ".b-warning": {
          borderColor: warning + " !important",
        },
        ".b-warning-light-1": {
          borderColor: lighterWarning + " !important",
        },
        ".b-warning-light-4": {
          borderColor: lighterWarning4 + " !important",
        },
        ".b-cta": {
          borderColor: ctaBackgroundColor + " !important",
        },
        ".bgc-primary": {
          backgroundColor: primaryColor + " !important",
        },
        ".bgc-cta": {
          backgroundColor: ctaBackgroundColor + " !important",
        },
        ".bg-cta": {
          background: ctaBackgroundColor + " !important",
        },
        ".black-button": {
          backgroundColor: "black !important",
          borderColor: "black !important",
          color: "white !important",
        },
        ".red-button": {
          backgroundColor: "red !important",
          color: "white !important",
        },
        ".textPrimary": {
          color: textPrimaryColor + " !important",
        },
        ".textSecondary": {
          color: textSecondaryColor + " !important",
        },
        ".pointer": {
          cursor: "pointer !important",
        },
        ".notAllowed": {
          cursor: "not-allowed !important",
        },
        ".hlight-primary:hover": {
          color: primaryColor + " !important",
        },
        ".disabled": {
          background: "#f0f0f0",
          borderColor: "#d9d9d9",
          color: "#bfbfbf",
        },
        ".hlight-black:hover": {
          color: "black" + " !important",
        },
        ".hlight-gray:hover": {
          color: "#bfbfbf" + " !important",
        },
        ".hlight-red:hover": {
          color: "#cc0000" + " !important",
        },
        ".hlight-white:hover": {
          color: "white" + " !important",
        },
        ".hlight-border-primary:hover": {
          borderColor: primaryColor + "!important",
        },

        ".hlight-bg-primary:hover": {
          backgroundColor: primaryColor + "!important",
        },

        ".lightenPrimaryBkd:hover": {
          backgroundColor: lighterPrimary + "!important",
          borderColor: lighterPrimary + "!important",
        },
        ".lightenPrimaryBkdChecked:checked": {
          backgroundColor: lighterPrimary + "!important",
          borderColor: lighterPrimary + "!important",
        },
        ".darkenPrimaryBkd:hover": {
          backgroundColor: darkerPrimary + "!important",
          borderColor: darkerPrimary + "!important",
        },
        ".primaryBkdWhiteTxt:hover": {
          backgroundColor: primaryColor + "!important",
          color: "white !important",
          borderColor: primaryColor + "!important",
        },
        ".whiteBkdBlackTxt:hover": {
          backgroundColor: "white !important",
          color: "black !important",
          borderColor: "white !important",
        },
        ".blackBkdWhiteTxt:hover": {
          backgroundColor: "black !important",
          color: "white !important",
          borderColor: "black !important",
        },
        ".midnightBkdWhiteTxt:hover": {
          backgroundColor: "#203b7e !important",
          color: "white !important",
          borderColor: "#203b7e !important",
        },
        ".lift:hover": {
          boxShadow: "rgb(0 0 0 / 20%) 0px 7px 8px !important",
          transform: "scale(1.004) translatey(-1px) !important",
          transition: "0.2s !important",
        },
        ".subtleLift:hover": {
          boxShadow: "rgb(0 0 0 / 20%) 0px 2px 3px !important",
          transform: "scale(1.0025) translatey(-1px) !important",
          transition: "0.1s !important",
        },
        ".subtleLiftNoShadow:hover": {
          transform: "scale(1.0025) translatey(-1px) !important",
          transition: "0.1s !important",
        },
        ".add-underline:hover": {
          textDecoration: "underline !important",
        },
        ".borderUnderline:hover": {
          borderBottom: "solid 1px !important",
        },
        ".subtleGrow:hover": {
          transform: "scale(1.02) !important",
        },
        ".grow:hover": {
          transform: "scale(1.25) !important",
          transition: "0.1s !important",
        },
        ".ant-btn.ant-btn-text": {
          color: textPrimaryColor,
        },
        ".ant-menu-item-selected": {
          color: primaryColor,
        },
        [selectorForAntMenu]: {
          borderBottom: `2px solid ${primaryColor} !important`,
        },
        ".ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected": {
          backgroundColor: pSBC(0.9, primaryColor),
        },
        ".ant-btn.ant-btn-link": {
          color: textSecondaryColor,
        },
        "h1, h2, h3, h4, h5, h6": {
          marginTop: 0,
        },

        // Intentional stutter to ensure that the hover state is applied.
        ".ant-btn.ant-btn.ant-btn.ant-btn.ant-btn.ant-btn:hover, .ant-btn:focus":
          {
            borderColor: primaryColor,
          },
        ".ant-btn.ant-btn-primary": {
          color: textPrimaryColor,
          background: primaryColor,
          borderColor: primaryColor,
        },
        ".ant-btn-icon-only.ant-btn-icon-only:hover": {
          borderColor: primaryColor + " !important",
        },
        ".ant-btn.ant-btn-primary:hover, .ant-btn.ant-btn-primary:focus": {
          background: darkerPrimary + " !important",
          borderColor: darkerPrimary + " !important",
        },
        ".ant-input-number-input.ant-input-number-input.ant-input-number-input.ant-input-number-input":
          {
            height: 30,
            fontSize: "16px",
          },
        ".ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner": {
          borderColor: primaryColor + " !important",
        },
        ".ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner":
          {
            backgroundColor: primaryColor + " !important",
          },
        p: {
          marginTop: 0,
        },
        ".ant-input-number-affix-wrapper, .ant-input-number, .ant-input-number .ant-input-number-input":
          {
            borderRadius: "0",
          },
        ".ant-input-number-outlined:hover, .ant-input-number-outlined:active, .ant-input-number-outlined:focus-within":
          {
            border: `1px solid ${primaryColor}`,
            borderColor: primaryColor + " !important",
            boxShadow: "none",
          },
        ".ant-input-number-affix-wrapper input.ant-input-number-input": {
          padding: 0,
        },
        ".ant-input-number-handler:hover .ant-input-number-handler-up-inner, .ant-input-number-handler:hover .ant-input-number-handler-down-inner":
          {
            color: primaryColor + " !important",
          },
        "ant-input:focus": {
          borderColor: darkerPrimary,
          outline: "solid 1px darkerPrimary",
        },
        //-------------------------------------------------------------------
        // SUBMIT DISABLE BUTTON STYLES - When ready to enable, delete corresponding code in design-system/components/molecules/button/button.scss
        //-------------------------------------------------------------------
        // ".ant-btn[disabled], .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active":
        //   {
        //     background: pSBC(0.9, textPrimaryColor),
        //     borderColor: pSBC(0.75, textPrimaryColor),
        //     color: pSBC(0.45, textPrimaryColor),
        //   },
        //-------------------------------------------------------------------
        ".ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected":
          {
            color: primaryColor,
          },
        ".ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover":
          {
            color: primaryColor,
          },
        ".ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:focus::after":
          {
            borderColor: primaryColor,
          },
        ".ant-typography.ant-typography-secondary": {
          color: textSecondaryColor,
        },
        "a.ant-typography.ant-typography-secondary:hover": {
          color: primaryColor,
        },
        ".ant-spin-dot-item": {
          backgroundColor: primaryColor,
        },
        ".ant-comment-content-author > a, .ant-comment-content-author > span": {
          color: textSecondaryColor,
        },
        ".ant-btn-icon-only.ant-input-search-button:hover": {
          borderColor: primaryColor + " !important",
        },
        ".ant-input:hover, .ant-input:focus, .ant-input-search, .ant-input:hover, .ant-input-search, .ant-input:focus":
          {
            borderColor: primaryColor + " !important",
          },
        ".ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover":
          {
            borderColor: primaryColor,
          },
        ".ant-select:not(.ant-select-disabled):hover .ant-select-selector": {
          borderColor: primaryColor,
        },
        ".ant-checkbox-checked .ant-checkbox-inner": {
          borderColor: primaryColor,
          backgroundColor: primaryColor,
        },
        ".ant-radio-checked .ant-radio-inner": {
          borderColor: primaryColor + " !important",
          backgroundColor: "white !important",
        },
        ".ant-radio-wrapper:hover .ant-radio-inner": {
          borderColor: primaryColor + " !important",
        },
        ".checkedFill.ant-radio-wrapper-checked": {
          backgroundColor: primaryColor + " !important",
          color: textPrimaryColor + "!important",
        },
        ".ant-radio-checked .ant-radio-inner:after": {
          backgroundColor: primaryColor + " !important",
        },
        ".ant-radio:hover .ant-radio-inner:": {
          borderColor: primaryColor + " !important",
        },
        ".ant-picker:hover .ant-picker-suffix:not(:last-child)": {
          opacity: 0.8,
        },
        ".ant-picker-outlined:focus-within": {
          boxShadow: "none",
        },
        ".ant-picker:hover, .ant-picker-focused, .ant-picker:focus-within": {
          borderColor: primaryColor,
        },
        ".ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after":
          {
            borderRight: `3px solid ${primaryColor}`,
          },
        ".ant-menu-submenu-selected": {
          color: primaryColor,
        },
        ".ant-switch-checked": {
          backgroundColor: primaryColor,
        },
        ".bg-transparent-corner-bottom": {
          background: `linear-gradient(135deg, ${primaryColor} 50%, transparent 50%)`,
        },
        ".bg-transparent-corner-top": {
          background: `linear-gradient(225deg, transparent 50%, ${primaryColor} 50%)`,
        },
        ".ant-carousel .slick-prev:hover, .ant-carousel .slick-next:hover": {
          color: primaryColor,
          border: `1px solid ${primaryColor}`,
        },
      },
    };
  },
  { name: "cotribute" }
);

const selectorForAntMenu =
  ".ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after";
