import React from "react";
import { BreakpointProvider } from "../../hooks/use-breakpoint";
import { DefaultThemeProvider } from "../../hooks";

const MobileView = ({
  minHeight = 300,
  maxWidth = 350,
  mockWidth = 800,
  children,
}: {
  children: React.ReactChild;
  minHeight?: number | null;
  maxWidth?: number | null;
  mockWidth?: number;
}) => {
  return (
    <DefaultThemeProvider>
      <BreakpointProvider mockWidth={mockWidth}>
        <div
          style={{
            minHeight: minHeight ? minHeight : undefined,
            maxWidth: maxWidth ? maxWidth : undefined,
            margin: "auto",
          }}
        >
          {children}
        </div>
      </BreakpointProvider>
    </DefaultThemeProvider>
  );
};

export default MobileView;
