import { useCallback } from "react";
import { AuthApi, api } from "@dreambigger/shared/src/api/acquire";
import useAuthHandler from "@dreambigger/shared/src/hooks/use-auth-handler";
import { UserType } from "@dreambigger/shared/src/types";
import { useSegment } from "@dreambigger/shared/src/hooks";

const authApi = new AuthApi(api);

export default function useOtp(
  financialInstitutionId?: string,
  userId?: string,
  userType?: UserType,
  trackingReference?: Record<string, string | undefined>,
  onOtpVerified?: () => void
) {
  const segment = useSegment();
  const { successHandler } = useAuthHandler(
    financialInstitutionId,
    trackingReference
  );

  const verifyOtp = useCallback(
    (codeValue: string, deviceFingerprint: string) => {
      if (!userId || !userType) {
        return;
      }
      authApi
        .verifyOtp({
          userId,
          codeValue,
          userType,
          deviceFingerprint,
        })
        .then(({ data }) => {
          if ("jwt" in data) {
            successHandler(data.jwt, "OTP Verified");
            onOtpVerified ? onOtpVerified() : null;
          }
        })
        .catch((error) => {
          // most likely the URL is ill-formed resulting in a 422
          segment.track({
            action: "Authentication",
            label: "OTP Token Verification Failed",
            properties: {
              error: error.message,
              ...trackingReference,
            },
          });
        });
    },
    [successHandler, trackingReference, userId, userType, segment]
  );

  return {
    verifyOtp,
  };
}
