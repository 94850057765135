//************************************************************************/
// USE APPCUES
// * This hook allows us to make appcue calls in a typescript-friendly format.
// * Appcues is a tool that allows our team to create interactive modals, pop-ups, and checklists
//   on pages without requiring development effort. In our apps, these modals are usually triggered
//   by passing a magic link in to the url, IE: "...{url}&tour=inspire-on-boarding-barnheart"
// * All of our appcue events (usally called flows) are stored on our dashboard at studio.appcues.com/
// * Appcues is strongly tied to making personalized experiences for individual users. Although there is
//   a method below for triggering an event with an anonymous user, we'll generally want to first identify
//   the user and only display appcue flows to them if they 'qualify' for it by conditions set on
//   on dashboard for each individual flow.
//************************************************************************/

import { Loader } from "appcues-loader";
import { useState } from "react";

// TYPE DEFINITION - Read Appcues Events Reference - https://docs.appcues.com/article/493-appcues-events-reference
type eventOption =
  | "flow_started"
  | "flow_completed"
  | "flow_skipped"
  | "step_started"
  | "step_completed"
  | "step_skipped"
  | "step_interacted"
  | "form_submitted"
  | "form_field_submitted"
  | "experience_started"
  | "icon_seen"
  | "step_seen"
  | "nps_survey_started"
  | "nps_score"
  | "nps_feedback"
  | "nps_ask_me_later_at"
  | "nps_clicked_update_nps_score"
  | "checklist_shown"
  | "checklist_completed"
  | "checklist_skipped"
  | "checklist_dismissed"
  | "checklist_item_started"
  | "checklist_item_completed";

const appcuesId = process.env.NEXT_PUBLIC_APPCUES_ID || "47682";

const useAppcues = () => {
  const [loaded, setLoaded] = useState(false);

  const initializeAppcues = async () => {
    try {
      if (!loaded) {
        const loader = new Loader(appcuesId);
        await loader.load();
        setLoaded(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    // *********** EXPORTED HOOK METHODS **************

    // IDENTIFY
    // * Identifies the current user with an ID and an optional set of properties.
    // * Behind the scenes, this call also invokes Appcues.page(). This is the one call that is required for Appcues
    //   to load and function on your site. Property values can be strings, numbers, or booleans.
    // * Beware! Any identify call with an array or nested object as a property value will not appear in your Appcues account.
    async identify(userId: string, userProperties?: Record<string, any>) {
      try {
        await initializeAppcues();
        return (window as any).Appcues.identify(userId, userProperties);
      } catch (error) {
        console.error(error);
      }
    },

    // GROUP
    // * Identifies the current group (the user's account, company, etc.) of the user with an ID and an optional set of properties.
    // * Property values can be strings, numbers, or booleans. A user can belong to multiple groups, but only one group can be identified at a time.
    // * Only the group most recently associated with the user will be considered for targeting.
    async group(groupId: string, groupProperties?: Record<string, any>) {
      try {
        await initializeAppcues();
        return (window as any).Appcues.group(groupId, groupProperties);
      } catch (error) {
        console.error(error);
      }
    },

    // ANONYMOUS
    // * Generates a session-based unique ID for the current user.
    // * -Warning- appcues is meant to be used with identified users and we get charged per hit,
    //   so avoid using this most of the time.
    async anonymous() {
      try {
        await initializeAppcues();
        return (window as any).Appcues.anonymous();
      } catch (error) {
        console.error(error);
      }
    },

    // PAGE
    // * Notifies the SDK that the state of the application has changed. You can use this to let appcues know when the URL has changed,
    //   which may be especially important in single-page apps.
    async page() {
      try {
        await initializeAppcues();
        return (window as any).Appcues.page();
      } catch (error) {
        console.error(error);
      }
    },

    // TRACK
    // * Tracks a custom event (by name) taken by the current user, along with any properties about that event.
    // * Currently, appcues does not support audience targeting based on event properties. However, properties can be used for filtering events
    //   in the Event Triggering feature (available on Enterprise plans only). See url:
    //   https://docs.appcues.com/article/450-event-triggering#:~:text=Event%20triggering%20means%20displaying%20an,than%20requiring%20a%20page%20load.
    async track(eventName: string, eventProperties?: Record<string, any>) {
      try {
        await initializeAppcues();
        return (window as any).Appcues.track(eventName, eventProperties);
      } catch (error) {
        console.error(error);
      }
    },

    // SHOW
    // * Forces specific Appcues content to appear for the current user by passing in the ID. This method ignores any targeting that is set on the flow or checklist.
    // * A checklist that has been force shown will take precedent over any other checklists.  The checklist will show even if it was previously dismissed.
    // * If used for a checklist that's already been shown to the user, the 'Congratulations' content will not appear upon completion.
    async show(contentId: string) {
      try {
        await initializeAppcues();
        return (window as any).Appcues.show(contentId);
      } catch (error) {
        console.error(error);
      }
    },

    // CLEAR SHOW
    // * Stops force showing any checklists shown using Appcues.show().
    async clearShow() {
      try {
        await initializeAppcues();
        return (window as any).Appcues.clearShow();
      } catch (error) {
        console.error(error);
      }
    },
    // ON
    // * Exectutes a callback function when the given event (see list above of event options) is triggered by the SDK.
    // * Learn more at https://docs.appcues.com/article/302-working-with-client-side-appcues-events
    async on(
      eventOption: eventOption,
      callbackFn: Function,
      context?: Record<string, any>
    ) {
      try {
        await initializeAppcues();
        return (window as any).Appcues.on(eventOption, callbackFn, context);
      } catch (error) {
        console.error(error);
      }
    },
    // OFF
    // * Undoes a corresponding "appcues.on()" callback function when a given event takes place (see list above of event options).
    async off(
      eventOption: eventOption,
      callbackFn: Function,
      context?: Record<string, any>
    ) {
      try {
        await initializeAppcues();
        return (window as any).Appcues.off(eventOption, callbackFn, context);
      } catch (error) {
        console.error(error);
      }
    },
    // ONCE
    // * Like on(), but triggers it once the next time an event is triggered by SDK.
    async once(
      eventOption: eventOption,
      callbackFn: Function,
      context?: Record<string, any>
    ) {
      try {
        await initializeAppcues();
        return (window as any).Appcues.once(eventOption, callbackFn, context);
      } catch (error) {
        console.error(error);
      }
    },

    // RESET
    // * Clears all known information about the current user in this session.
    //   This call will clear the flow in progress and wipe any data appcues generated for a user.
    // * This is primarily useful when a user logs out of the application.
    async reset() {
      try {
        await initializeAppcues();
        return (window as any).Appcues.reset();
      } catch (error) {
        console.error(error);
      }
    },

    // DEBUG
    // * Puts the SDK in debug mode, showing more information about the SDK's inner workings.
    async debug() {
      try {
        await initializeAppcues();
        return (window as any).Appcues.debug();
      } catch (error) {
        console.error(error);
      }
    },
  };
};
export default useAppcues;
