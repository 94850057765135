import cx from "classnames"
import React from "react"
import styles from "./group.module.scss"

export interface PaneProps  {
	spacing?: Spacing
	className?: string
	as?: any
  wrap?: boolean
  style?: React.CSSProperties
  xs?: Orientation
  sm?: Orientation
  md?: Orientation
  lg?: Orientation
  xl?: Orientation
	[x:string]:any
}

type Spacing = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
type Orientation = "horizontal" | "vertical"

export const Group: React.FC<PaneProps> = ({
	xs = "vertical",
	sm,
	md,
	lg,
	xl,
	spacing,
	className,
	as = "div",
  wrap = false,
	...rest
}) => {
	const Element = as || "div"
	return (
		<Element
			className={cx(
				styles.group,
				{
					[styles[`${xs}Orientation`]]: xs,
					[styles[`sm_${sm}Orientation`]]: sm,
					[styles[`md_${md}Orientation`]]: md,
					[styles[`lg_${lg}Orientation`]]: lg,
					[styles[`xl_${xl}Orientation`]]: xl,
					[styles[`spacing-${spacing}`]]: spacing,
          [styles[`wrap`]]: wrap,
				},
				className
			)}
			{...rest}
		/>
	)
}

export default Group
