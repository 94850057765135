import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

export type BreakpointReturn = {
  breakpoint: Breakpoint;
  isNarrowerThan: (breakpointToCheck: Breakpoint) => boolean;
  isWiderThan: (breakpointToCheck: Breakpoint) => boolean;
};
const ScreenSizeContext = createContext<BreakpointReturn>({} as any);

export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
const pixelsByBreakpoint = {
  xs: 375,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1500,
  xxl: 2000,
};

const calculateBreakpoint = (width: number): Breakpoint => {
  if (width < pixelsByBreakpoint.sm) {
    return "xs";
  } else if (width >= pixelsByBreakpoint.sm && width < pixelsByBreakpoint.md) {
    return "sm";
  } else if (width >= pixelsByBreakpoint.md && width < pixelsByBreakpoint.lg) {
    return "md";
  } else if (width >= pixelsByBreakpoint.lg && width < pixelsByBreakpoint.xl) {
    return "lg";
  } else if (width >= pixelsByBreakpoint.xl && width < pixelsByBreakpoint.xxl) {
    return "xl";
  } else {
    return "xxl";
  }
};

// const showMenu = isWiderThan("md")
const BreakpointProvider = ({
  mockWidth,
  ...rest
}: {
  mockWidth?: number;
  [x: string]: any;
}) => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>("lg");

  const isWiderThan = useCallback(
    (breakpointToCheck: Breakpoint) => {
      return (
        pixelsByBreakpoint[breakpoint] > pixelsByBreakpoint[breakpointToCheck]
      );
    },
    [breakpoint]
  );

  const isNarrowerThan = useCallback(
    (breakpointToCheck: Breakpoint) => {
      return (
        pixelsByBreakpoint[breakpoint] < pixelsByBreakpoint[breakpointToCheck]
      );
    },
    [breakpoint]
  );

  useEffect(() => {
    function getBreakPoint() {
      setBreakpoint(calculateBreakpoint(mockWidth || window.innerWidth));
    }
    getBreakPoint();

    window.addEventListener("resize", getBreakPoint);
    return () => window.removeEventListener("resize", getBreakPoint);
  }, []);

  return (
    <ScreenSizeContext.Provider
      value={{ breakpoint, isWiderThan, isNarrowerThan } as BreakpointReturn}
      {...rest}
    />
  );
};

const useBreakpoint = () => useContext(ScreenSizeContext);
export { BreakpointProvider, useBreakpoint };
