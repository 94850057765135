import React, { memo } from "react";
import { BreakpointProvider } from "../../..";
import cx from "classnames";
import styles from "./default-app-layout.module.scss";

const DefaultAppLayout = ({
  nav,
  children,
  footer,
  fixed,
  backgroundColor,
}: {
  nav: React.ReactNode;
  footer: React.ReactNode;
  children: React.ReactChild;
  fixed?: boolean;
  backgroundColor?: string;
}) => {
  return (
    <BreakpointProvider>
      <div
        className={cx(
          fixed ? styles.fixedHeight : null,
          "flex flex-column w-100"
        )}
        style={{ backgroundColor: backgroundColor }}
      >
        <div
          className="flex sticky top-0 bg-white z-999"
          style={{ flex: "0 0 auto" }}
        >
          {nav}
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateRows: "1fr auto",
            minHeight: "calc(100vh - 75px)",
          }}
        >
          <main
            className="max-page-width overflow-x-hidden lg_overflow-y-hidden w-100"
            style={{ flex: "1 1 auto" }}
          >
            {children}
          </main>
          <div>{footer}</div>
        </div>
      </div>
    </BreakpointProvider>
  );
};

export default memo(DefaultAppLayout);
