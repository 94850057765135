const namespace = "ct";

class LocalStorage {
  baseKey: string;

  constructor(baseKey = "shared") {
    this.baseKey = baseKey;
  }

  // All the paths for this api domain.
  buildKey = (key: string) => `${namespace}/${this.baseKey}/${key}`;

  getItem = (key: string) => {
    if (!process?.browser) {
      // Running server side.
      return "";
    }
    return localStorage.getItem(this.buildKey(key)) || "";
  };

  setItem = (key: string, value: string) => {
    if (!process?.browser) {
      // Running server side.
      return;
    }

    localStorage.setItem(this.buildKey(key), value);
  };

  removeItem = (key: string) => {
    if (!process?.browser) {
      // Running server side.
      return;
    }

    localStorage.removeItem(this.buildKey(key));
  };
}

export default LocalStorage;

const clearableLogoutItems = [
  "ct-user",
  "ct-push",
  "ct-bdg",
  "ct-groups",
  "token",
  "tokents",
  "jwt-auth",
  "jwt-refresh",
  "cache-timeouts",
  "cache-events",
  "cache-stories",
  "cache-collections",
  "cache-organization",
  "cache-community",
  "cache-communities",
];

/**
 * Removes specific items from local storage when a user logs out.
 * Keys to remove are based on what the Dreambig repo currently
 * removes from its localStorage when a user logs out.
 */
export const removeLogoutLocalStorageItems = () =>
  clearableLogoutItems.forEach((key: string) => localStorage.removeItem(key));
