import { ThemeProvider as JSSThemeProvider } from "react-jss";
import React, { useEffect, useState } from "react";
import {
  DefaultColors,
  DefaultThemeStyles,
  useDefaultStyles,
} from "./themes/default";

export const DefaultThemeProvider = ({
  colors,
  children,
}: {
  colors?: Partial<DefaultColors>;
  children: React.ReactNode;
}) => {
  const [show, setShow] = useState(false);
  const theme: DefaultThemeStyles = { colors };
  useDefaultStyles({ theme });

  useEffect(() => {
    // Using a timeout so the theme css file has enough time to load.
    // This is to solve a flicker in the css styles on load.
    setTimeout(() => {
      setShow(true);
    }, 10);
  }, []);

  return (
    <JSSThemeProvider theme={theme}>
      <div children={children} style={{ display: show ? undefined : "none" }} />
    </JSSThemeProvider>
  );
};
