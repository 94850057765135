import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { memo } from "react";
import { Spin, Typography, Group } from "../../atoms";
import { useMixpanel, useGA, useSegment } from "@dreambigger/shared/src/hooks";
import InspireAnalytics, {
  InspireAnalyticEvents,
} from "@dreambigger/shared/src/utils/inspire-analytics-helper";

const Link = Typography.Link;

type NextAndPreviousBarProps = {
  nextText?: string;
  loading?: boolean;
  showLeft?: boolean;
  showRight?: boolean;
  centerText?: string;
  centerFunction?: Function;
  previousText?: string;
  actionTextClass?: string;
  centerTextClass?: string;
  className?: string;
  analyticsLabel?: string;
  previousFunction: (values?: any) => void;
  nextFunction: (values?: any) => void;
};

const NextAndPreviousBar = ({
  loading = false,
  showLeft = true,
  showRight = true,
  nextText = "Next",
  nextFunction,
  previousText = "Previous",
  previousFunction,
  centerText,
  centerFunction,
  centerTextClass,
  actionTextClass,
  className,
  analyticsLabel,
}: NextAndPreviousBarProps) => {
  if (loading) {
    return (
      <div className="w-100 justify-between pa-3 tc" style={{ minHeight: 54 }}>
        <Spin />
      </div>
    );
  }

  // Load Analytics and Appcues
  const mixpanel = useMixpanel();
  const segment = useSegment();
  const googleAnalytics = useGA();

  const handleClickAnalytics = (button: string) => {
    // Log analytics when user clicks a button.
    googleAnalytics.event({
      category: "Button Click",
      action: `NextAndPreviousBar: ${button} Clicked`,
      label: analyticsLabel || window.location.href,
    });
    InspireAnalytics.track({
      eventName: InspireAnalyticEvents.INVITE_CLICK,
      properties: {
        Page: window.location.href,
        "Button Clicked": button,
      },
      mixpanel,
      segment,
    });
  };

  return (
    <>
      {/* Mobile Only - centerText displays above bar */}
      {centerText &&
        (centerFunction ? (
          <div
            className={`${centerTextClass} tc pointer dn md_db`}
            onClick={() => {
              handleClickAnalytics("center");
              centerFunction();
            }}
          >
            {centerText}
          </div>
        ) : (
          <div className={`${centerTextClass} tc db md_dn`}>{centerText}</div>
        ))}
      <Group xs="horizontal" className={`${className} justify-between`}>
        <div className="min-w-10">
          {showLeft && (
            <Link
              className={`${actionTextClass} tl add-underline`}
              onClick={() => {
                handleClickAnalytics("previous");
                previousFunction();
              }}
            >
              <LeftOutlined className="mr-1" />
              {previousText}
            </Link>
          )}
        </div>
        {/* Desktop Only - centerText displays in bar */}
        {centerText &&
          (centerFunction ? (
            <div
              className={`${centerTextClass} tc pointer dn md_db`}
              onClick={() => {
                handleClickAnalytics("center");
                centerFunction();
              }}
            >
              {centerText}
            </div>
          ) : (
            <div className={`${centerTextClass} tc dn md_db`}>{centerText}</div>
          ))}

        <div className="min-w-10 flex">
          {showRight && (
            <Link
              className={`${actionTextClass} tr add-underline ml-auto`}
              onClick={() => {
                handleClickAnalytics("next");
                nextFunction();
              }}
            >
              {nextText}
              <RightOutlined className="ml-1" />
            </Link>
          )}
        </div>
      </Group>
    </>
  );
};

export default memo(NextAndPreviousBar);
