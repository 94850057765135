//************************************************************************/
// USE GOOGLE ANALYTICS
// * Allows us to easily make google analytics calls when loaded in a file.
//   See documentation at https://github.com/react-ga/react-ga
//
// * Find our tracking codes for our different environments on our google analytics dashboard at our admin dashboard
//   https://analytics.google.com/analytics/web/?authuser=1#/a56736903w310972746p264852040/admin
//
// * Additional package commands can be added to this hook in the future.
//
// * NOTE - We are currently using a universal tracking number. We need to upgrade to GA-4 by July 1, 2023
//   and use the new tracking numbers we are provided.
//************************************************************************/

import { useEffect, useState } from "react";
import ReactGA, {
  InitializeOptions,
  EventArgs,
  TrackerNames,
  FieldsObject,
} from "react-ga";
import { User } from "../types";

// Load Google Anayltics Tracking ID - Defaults to "Review" key if no other env key is passed in.
const defaultTrackingId =
  process.env.NEXT_PUBLIC_GA_TRACKING_ID || "UA-56736903-10";

const useGA = () => {
  // CHECK IF GA LOADED
  // * If not, initialize it using default settings (it can also be called at any time with initialize command below).
  //   The useEffect and (window) check are necessary to prevent errors stemming from initial static generation.
  //   Timeout gives time for an initialize command to be run before the default is loaded.
  useEffect(() => {
    if (window) {
      setTimeout(() => {
        const isReactGaLoaded = ReactGA.ga();
        if (!isReactGaLoaded) {
          ReactGA.initialize(defaultTrackingId);
        }
      }, 1000);
    }
  }, []);

  return {
    // INITIALIZE()
    // * Create a new instance of ReactGA. This should be done once in the app at an upper level, before any other uses of this hook
    //   are loaded, or a default version will have already been loaded as a safeguard. Additional calls will not create another instance.
    // * Example: initalize("UA-56736903-10", {debug: true} )
    initialize(
      gaTrackingID: string = defaultTrackingId,
      options?: InitializeOptions
    ) {
      return ReactGA.initialize(gaTrackingID, options);
    },

    // SET()
    // * Used to create custom dimensions in Google Analytics. Custom dimensions allow us to make custom reports on our analytics dashboard.
    //   For example, if we wanted to make a report that shows pageviews by a user's organization, we could store userOrg as a custom dimension.
    // * Example: set(dimension9: 'userOrg') --- Refer to the dimension by number.
    set(fieldsObject: FieldsObject, trackerNames?: TrackerNames) {
      return ReactGA.set(fieldsObject, trackerNames);
    },

    // PAGEVIEW()
    // * Tracks a url hit.
    // * Example: .pageview('/about/contact-us'))
    pageview(path: string, trackerNames?: TrackerNames, title?: string) {
      return ReactGA.pageview(path, trackerNames, title);
    },

    // MODALVIEW()
    // * Tracks a view hit that doesn't require a URL change.
    // * Example: .modalview('/about/contact-us')
    modalview(modalName: string) {
      return ReactGA.modalview(modalName);
    },

    // EVENT()
    // * Tracks an event. This is the main function to be called us ing Google Analytics.
    // * Args is a {} that can include category (string), action (string), label? (string),
    //  value? (string), and nonInteraction? (boolean)
    // * Example: .event({category: 'user', action: 'clicked', label: 'buttonClick'})
    event(args: EventArgs) {
      return ReactGA.event(args);
    },

    // GET GA CLIENT ID()
    // * Returns the client ID that is associated with the connected visitor.
    // * Example: "1819754886.1649883311"
    getGaClientId() {
      let gaClientId;
      ReactGA.ga((tracker: any) => {
        gaClientId = tracker.get("clientId"); // gaClientId defined in upper scope
      });
      return gaClientId;
    },

    // GENERATE HIT ID()
    // * Generates a random hit id.
    // * Example: "bd729a70-1c06-059f-aead-161fc41368ed"
    generateHitId() {
      function genSegment(addDash?: boolean) {
        var idSegment = (Math.random().toString(16) + "000000000").substring(
          2,
          10
        );
        return addDash
          ? "-" + idSegment.substring(0, 4) + "-" + idSegment.substring(4, 8)
          : idSegment;
      }
      return genSegment() + genSegment(true) + genSegment(true) + genSegment();
    },

    // SET DEFAULT DIMENSIONS()
    setDefaultDimensions(orgUrl?: string, user?: User) {
      const uniqueId = this.generateHitId();
      return ReactGA.set({
        dimension1: orgUrl || "", // orgUrl
        dimension2: user?.organization?.slug || "", // userOrg
        dimension3: user?.id.toString() || "", // userID
        dimension6: user ? "true" : "false", // loggedIn
        dimension7: uniqueId || "", // HitID
        dimension8: new Date().getTime().toString(), // timestamp
        dimension9: this.getGaClientId() || "", // gaClientId
        dimension10: uniqueId || "", //uniqueId (same as HitId for some reason)
      });
    },
  };
};

export default useGA;
