import { AxiosInstance } from "axios";
import { FinancialInstitutionBootstrap } from "../../types";

// All the paths for this api domain.
export const basePath = "/financial-institutions";

export const financialInstitutionPaths = {
  bootstrap: (id: string) => basePath + `/${id}/bootstrap`,
  getActiveIds: () => basePath + `/active-ids`,
};

class FinancialInstitutionApi {
  api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  // Get financial institution record with the skins.
  // Get /financial-institutions/<id>/bootstrap
  bootstrap = (id: string) => {
    return this.api.get<FinancialInstitutionBootstrap>(
      financialInstitutionPaths.bootstrap(id)
    );
  };

  // Get active financial institution ids.
  // Get /financial-institutions/<id>
  getActiveIds = () => {
    return this.api.get(financialInstitutionPaths.getActiveIds());
  };
}

export default FinancialInstitutionApi;
