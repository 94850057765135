import { useMemo } from "react";
import jwt from "jsonwebtoken";
import isPast from "date-fns/isPast";
import { PasswordlessJwtTokenData } from "@dreambigger/shared/src/types";
import { LocalStorage } from "@dreambigger/shared/src/utils";

const jwtTokenKey = "jwt";

const useAuthToken = (baseKey = "passwordless-auth") => {
  const localStorage = useMemo(() => {
    return new LocalStorage(baseKey);
  }, [baseKey]);

  const getJwtToken = () => {
    return localStorage.getItem(jwtTokenKey) || "";
  };

  const setJwtToken = (token: string) => {
    localStorage.setItem(jwtTokenKey, token);
  };

  const decodeJWT = (jwtToken: string) => {
    return jwt.decode(jwtToken) as PasswordlessJwtTokenData | undefined;
  };

  const removeJwtToken = () => {
    localStorage.removeItem(jwtTokenKey);
  };

  const formatAuthHeader = (jwtToken: string) => {
    return `Bearer ${jwtToken}`;
  };

  const jwtIsExpired = () => {
    const token = localStorage.getItem(jwtTokenKey) || "";

    if (!token) {
      return true;
    }
    const data = jwt.decode(token) as PasswordlessJwtTokenData;
    const exp = new Date(data.exp * 1000);

    if (isPast(exp)) {
      return true;
    }

    return false;
  };

  return {
    getJwtToken,
    setJwtToken,
    decodeJWT,
    removeJwtToken,
    formatAuthHeader,
    jwtIsExpired,
  };
};
export default useAuthToken;
