import { Interval, isWithinInterval } from "date-fns";
import { getDateFnsTimeZone } from ".";
import { formatInTimeZone } from "date-fns-tz";

const getFormattedEventTimes = (
  startAt: string,
  endAt: string,
  timeZone: string
) => {
  // *************************************************************
  // * 1. FORMAT EVENT TIME DETAILS
  // *************************************************************

  // * Convert DB timeZone into a format readable by dateFns. Return if conversion fails.
  //   For a full list of all date-fns parsing options, see https://date-fns.org/v2.28.0/docs/parse
  const fnsTimeZone = getDateFnsTimeZone(timeZone);
  if (!fnsTimeZone) {
    return;
  }

  const abbrTimezone = startAt
    ? formatInTimeZone(startAt, fnsTimeZone, "zzz")
    : "";

  // * Define START TIME variables
  const startDate = startAt
    ? formatInTimeZone(startAt, fnsTimeZone, "MMM dd, yyyy")
    : "Coming Soon!";
  const startTime = startAt
    ? formatInTimeZone(startAt, fnsTimeZone, "h:mm a")
    : "Check back later for more information";

  // * Define END TIME variables if a valid start date has been provided/parsed.
  const hasValidEndAt = startAt && endAt && true;
  const endTime = hasValidEndAt
    ? formatInTimeZone(endAt, fnsTimeZone, "h:mm a")
    : null;
  const endDate = hasValidEndAt
    ? formatInTimeZone(endAt, fnsTimeZone, "MMM dd, yyyy")
    : null;

  // * Check if event is happening now.
  let happeningNow = false;
  if (startAt && endAt) {
    // -- Define the interval between the start and end date.
    const interval: Interval = {
      start: new Date(startAt),
      end:
        // -- If start and end dates are the same, set the default end time to before midnight
        startDate !== endDate
          ? new Date(endAt)
          : new Date(new Date(startAt).setHours(23, 59)),
    };

    // -- Check if the current time is within the interval.
    const now = new Date();
    if (isWithinInterval(now, interval)) {
      happeningNow = true;
    }
  }

  // *************************************************************
  // * 2. CREATE COMMONLY RETURNED OUTPUTS OF FORMATTED DETAILS
  // *************************************************************

  // DATES - Example: "May 19, 2022 - Jun 02, 2022"
  const dates = `${startDate} ${
    endDate && startDate !== endDate ? `- ${endDate}` : ""
  }`;

  // TIMES - Example: "5:00 PM - 8:00 PM"
  const times = `${startTime} ${
    endTime && startTime !== endTime ? `- ${endTime}` : ""
  }`;

  // *************************************************************
  // * 3. PACKAGE ALL RESULTS AND RETURN
  // *************************************************************
  const formattedEventTime = {
    startDate,
    startTime,
    endDate,
    endTime,
    abbrTimezone,
    happeningNow,
    dates,
    times,
  };
  return formattedEventTime;
};

export default getFormattedEventTimes;
