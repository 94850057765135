import React from "react";
import Head from "next/head";
import cotributeDefaults from "@dreambigger/shared/src/assets/cotribute-defaults";

export interface HeadProps {
  favicon?: string;
  title?: string;
  description?: string;
  siteName?: string;
  image?: string;
}

const CotributeHead: React.FC<HeadProps> = ({
  favicon,
  title,
  description,
  siteName,
  image,
}: HeadProps) => {
  const socialUrl = process?.browser ? window.location.href : "";

  return (
    <Head>
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href={favicon || cotributeDefaults.tabIconIco}
      />
      <title>{title || cotributeDefaults.title}</title>
      <meta property="og:title" content={title || cotributeDefaults.title} />
      <meta
        property="og:description"
        content={description || cotributeDefaults.description}
      />
      <meta
        property="og:site_name"
        content={siteName || cotributeDefaults.name}
      />
      <meta property="og:image" content={image || cotributeDefaults.image} />
      <meta property="og:image:width" content="1600" />
      <meta property="og:image:height" content="800" />
      <meta property="og:url" content={socialUrl} />
      <meta property="og:type" content="website" />
      <meta
        property="fb_app:id"
        content={process.env.NEXT_PUBLIC_FB_ID || ""}
      />
    </Head>
  );
};

//NOTE: This is exported out of design-system under the alias "Head"
export default CotributeHead;
