import { get } from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import { Conditional as ConditionalType } from "../../types";

dayjs.extend(utc);
dayjs.extend(duration);

const Conditional = class {
  static evaluate({
    conditions,
    data,
  }: {
    conditions?: ConditionalType.Condition | Array<ConditionalType.Condition>;
    data: Record<string, any>;
  }): boolean {
    if (!conditions) {
      return true;
    }
    if (!Array.isArray(conditions)) {
      conditions = [conditions];
    }

    // Each array element is an OR condition, hence the conditions.some
    // Each object key is an AND condition, hence the Object.entries.every
    return conditions.some((condition: ConditionalType.Condition) =>
      Object.entries(condition).every(([key, value]) =>
        this.testExpression(get(data, key), value)
      )
    );
  }

  static testExpression(
    data: ConditionalType.Value | Array<ConditionalType.Value> | object,
    exp: ConditionalType.Expression
  ): boolean {
    if (!Array.isArray(exp)) {
      exp = [exp, "$eq"];
    }

    const [value, operator, options] = exp;

    if (operator === "$exists") {
      switch (value) {
        case true:
          return data !== undefined;
        case false:
          return data === undefined;
        default:
          return false;
      }
    }

    if (Array.isArray(data)) {
      // typeof Array<Value>
      return data.some((element: ConditionalType.Value) =>
        this.testExpression(element, exp)
      );
    }
    if (
      typeof data === "object" &&
      data !== null &&
      // dayjs objects are allowed for $after", "$before
      !(["$after", "$before"].includes(operator) && dayjs.isDayjs(data))
    ) {
      return false;
    }

    switch (operator) {
      case "$in":
        return Array.isArray(value) && value.includes(data as any);

      case "$nin":
        return Array.isArray(value) && !value.includes(data as any);

      case "$ne":
        if (value === null) {
          // since JSON doesn't have undefined, we use null to represent undefined
          return data !== null && data !== undefined;
        }
        return data !== value;

      case "$lt":
      case "$lte":
      case "$gt":
      case "$gte":
        if (typeof data === "string") {
          data = parseFloat(data);
        }
        if (typeof data !== "number") {
          return false;
        }
        if (isNaN(data)) {
          return false;
        }
        switch (operator) {
          case "$lt":
            return value !== null && value !== undefined && data < value;
          case "$lte":
            return value !== null && value !== undefined && data <= value;
          case "$gt":
            return value !== null && value !== undefined && data > value;
          case "$gte":
            return value !== null && value !== undefined && data >= value;
        }
      case "$between":
        if (typeof data === "string") {
          data = parseFloat(data);
        }
        if (typeof data !== "number") {
          return false;
        }
        if (isNaN(data)) {
          return false;
        }

        // check if value is an array of length two
        if (!Array.isArray(value) || value.length !== 2) {
          return false;
        }

        // check if value[0] and value[1] are numbers
        if (
          typeof value[0] !== "number" ||
          typeof value[1] !== "number" ||
          isNaN(value[0]) ||
          isNaN(value[1])
        ) {
          return false;
        }

        // check if value[0] is less than value[1]
        if (value[0] > value[1]) {
          return false;
        }

        // check if data is a number
        if (
          data === null ||
          data === undefined ||
          typeof data !== "number" ||
          isNaN(data)
        ) {
          return false;
        }

        return data >= value[0] && data <= value[1];

      case "$after":
        if (!dayjs.isDayjs(data) && typeof data !== "string") {
          return false;
        }
        if (!dayjs(data).isValid()) {
          return false;
        }
        if (typeof value !== "number") {
          // guard clause to resolve TS error in test file
          return false;
        }
        return dayjs(data).isAfter(
          dayjs.utc().add(dayjs.duration({ [options]: value }))
        );

      case "$before":
        if (!dayjs.isDayjs(data) && typeof data !== "string") {
          return false;
        }
        if (!dayjs(data).isValid()) {
          return false;
        }
        if (typeof value !== "number") {
          // guard clause to resolve TS error in test file
          return false;
        }
        return dayjs(data).isBefore(
          dayjs.utc().add(dayjs.duration({ [options]: value }))
        );

      case "$regex":
        if (data === null || data === undefined || typeof data !== "string") {
          return false;
        }
        const regexPattern = new RegExp(value, options);
        return regexPattern.test(data);

      case "$eq":
      default:
        if (value === null) {
          // since JSON doesn't have undefined, we use null to represent undefined
          return data === null || data === undefined;
        }
        return data === value;
    }
  }
};

export default Conditional;
