import {
  DatePicker as AntdDatePicker,
  DatePickerProps as AntDatePickerProps,
} from "antd";
import IMask from "imask";
import dayjs from "dayjs";
import "./datepicker.scss";
import { DateOptions } from "@dreambigger/shared/src/types";

type Config = {
  config?: DateOptions;
};

export type DatePickerProps = Config &
  Omit<AntDatePickerProps, "format" | "picker" | "onKeyDown" | "disabledDate">;

const DatePicker = (props: DatePickerProps) => {
  // iMask configuration for masking the input while typing.
  const DATE_FORMAT = "MM/DD/YYYY";
  const MASKED = IMask.createMask({
    blocks: {
      DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
      MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
      YYYY: { from: 1900, mask: IMask.MaskedRange, to: 2999 },
    },
    format: (date: Date) => dayjs(date).format(DATE_FORMAT),
    mask: Date,
    parse: (date: string) => dayjs(date, DATE_FORMAT),
    pattern: DATE_FORMAT,
    autofix: true,
  });

  const keyDownHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    // Applies mask to input as the user types.
    const input = event.target as HTMLInputElement;
    setTimeout(() => {
      input.value = MASKED.resolve(input.value);
    }, 10);
  };

  // Upon opening calendar, every date rendered in the current view is evaluated by this function to see if it should be disabled.
  // * Returning TRUE sets the date to be disabled. *
  const getDisabledDates = (date: any) => {
    // If no disabled dates have been passed in from configuration, enable all the dates (return false)
    if (!props.config) {
      return false;
    }
    const config = props.config;

    // --- CASE: "DISABLE ALL DATES AFTER" ---
    // *** Example: Disable all future dates after today.
    if (config?.disableAllDatesAfter) {
      // Set start date for disable check to be "today" or a specified day formatted as "MM/DD/YYYY"
      const startDate =
        config.disableAllDatesAfter === "today"
          ? dayjs().endOf("day")
          : dayjs(config.disableAllDatesAfter, "MM/DD/YYYY");
      // If the passed in date is greater than the start date, disable.
      if (date > startDate) return true;
    }

    // --- CASE: "DISABLE ALL DATES BEFORE" ---
    // *** Example: Disable all dates before January 1, 2020.
    if (config?.disableAllDatesBefore) {
      //
      const startDate =
        // Set start date for disable check to be "today" or a specified day formatted as "MM/DD/YYYY"
        config.disableAllDatesBefore === "today"
          ? dayjs().endOf("day")
          : dayjs(config.disableAllDatesBefore, "MM/DD/YYYY");
      if (date < startDate) return true;
    }

    // --- CASE: "DISABLE A NUMBER OF DATES BEFORE" ---
    // *** Example: Disable 18 years before today (Prevent minors from applying)
    if (config?.disableNumDatesBefore) {
      const dConfig = config.disableNumDatesBefore;
      // Ensure that all needed values are provided.
      if (dConfig.before && dConfig.timeUnit && dConfig.number) {
        // Set startDate for disable check to be "today" or a specified day formatted as "MM/DD/YYYY"
        const startDate =
          dConfig.before === "today"
            ? dayjs().endOf("day")
            : dayjs(dConfig.before, "MM/DD/YYYY");
        // Get the difference in specified timeUnit (days/months/years) from the startDate (Example: 2 years)
        const differenceFromStartDate = date.diff(startDate, dConfig.timeUnit);
        // Get the difference in specified time units (days/months/years) of the passed-in date from the startDate (Example: 2 years)
        // *** Example: difference = 2 years and config number = 18 years, so disable.
        if (
          Math.abs(differenceFromStartDate) < dConfig.number &&
          dayjs(date).isBefore(startDate)
        ) {
          return true;
        }
      }
    }

    // --- CASE: DISABLE A NUMBER OF DATES AFTER ---
    // *** Example: Disable 7 days after today (Prevent users from requesting a loan sooner than a week from now)
    if (config?.disableNumDatesAfter) {
      const dConfig = config.disableNumDatesAfter;
      // Ensure that all needed values are provided.
      if (dConfig.after && dConfig.timeUnit && dConfig.number) {
        // Set start date as "today" or create a dayjs object from the string passed in, which must be formatted "MM/DD/YYYY"
        const startDate =
          dConfig.after === "today"
            ? dayjs().endOf("day")
            : dayjs(dConfig.after, "MM/DD/YYYY");
        // Get the difference in specified time units (days/months/years) of the passed-in date from the startDate (Example: 2 years)
        const differenceFromStartDate = date.diff(startDate, dConfig.timeUnit);
        // If the difference is <= the config number and IS AFTER the startDate, then disable.
        // *** Example: difference = 2 years and config number = 18 years, so disable.
        if (
          Math.abs(differenceFromStartDate) < dConfig.number &&
          dayjs(date).isAfter(startDate)
        ) {
          return true;
        }
      }
    }

    return false;
  };

  return (
    <AntdDatePicker
      format={DATE_FORMAT}
      onKeyDown={keyDownHandler}
      picker="date"
      placeholder={DATE_FORMAT}
      disabledDate={getDisabledDates}
      {...props}
    />
  );
};

export default DatePicker;
