import { UserType } from "@dreambigger/shared/src/types";
import { AxiosInstance } from "axios";

const basePath = "/auth";

// All the paths for this api domain.
const paths = {
  authenticateGuest: () => basePath + "/guest",
  sendOtp: () => basePath + "/otp/send",
  verifyOtp: () => basePath + "/otp/verify",
  verifyMagicLink: () => basePath + "/magic-link/verify",
};

class AuthApi {
  api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }
  // send OTP.
  // POST /auth/otp/send
  sendOtp = ({
    otpId,
    otpType,
    userType,
    financialInstitution,
    deviceFingerprint,
  }: {
    otpId: string;
    otpType: string;
    userType: UserType;
    financialInstitution: {
      id: string;
      name: string;
      notificationAssets: Record<string, any>;
    };
    deviceFingerprint: string;
  }) => {
    return this.api.post<{
      otpCode: {
        id: string;
      };
      user: {
        id: string;
        type: UserType;
      };
    }>(paths.sendOtp(), {
      login: {
        id: otpId,
        type: otpType,
      },
      userType,
      financialInstitution,
      deviceFingerprint,
    });
  };

  // authenticate guest
  // POST /auth/guest
  authenticateGuest = ({
    loginId,
    loginType,
    userType,
    financialInstitution,
    deviceFingerprint,
    recaptcha,
  }: {
    loginId: string;
    loginType: string;
    userType: UserType;
    financialInstitution: {
      id: string;
      name: string;
      notificationAssets: Record<string, any>;
    };
    deviceFingerprint: string;
    recaptcha?: {
      responseToken: string;
      action: string;
    };
  }) => {
    return this.api.post<{
      jwt: string;
      user: {
        id: string;
        type: UserType;
      };
    }>(paths.authenticateGuest(), {
      login: {
        id: loginId,
        type: loginType,
      },
      userType,
      financialInstitution,
      deviceFingerprint,
      recaptcha,
    });
  };

  // verify OTP.
  // POST /auth/otp/verify
  verifyOtp = ({
    userId,
    codeValue,
    userType,
    deviceFingerprint,
  }: {
    userId: string;
    codeValue: string;
    userType: UserType;
    deviceFingerprint: string;
  }) => {
    return this.api.post<{
      jwt: string;
      user: {
        id: string;
        type: UserType;
      };
    }>(paths.verifyOtp(), {
      userId,
      verificationCode: codeValue,
      userType,
      deviceFingerprint,
    });
  };

  // verify magic link.
  // POST /auth/magic-link/verify
  verifyMagicLink = ({
    userType,
    token,
    deviceFingerprint,
  }: {
    userType: UserType;
    token: string;
    deviceFingerprint: string;
  }) => {
    return this.api.post<
      {
        user: {
          id: string;
          type: UserType;
          login: {
            id: string;
            type: string;
          };
        };
      } & ({ jwt: string } | { needsOtpVerification: boolean })
    >(paths.verifyMagicLink(), {
      userType,
      token,
      deviceFingerprint,
    });
  };
}

export default AuthApi;
