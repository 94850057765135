import { useEffect } from "react";

/**
 * Cloudsponge API Key
 */
export const CLOUDSPONGE_API_KEY =
  process.env.NEXT_PUBLIC_CLOUDSPONGE_API_KEY ||
  "81367dfb8a9e9124287574126e4573b41e7d87b3";

export const CLOUDSPONGE_API_URL = `https://api.cloudsponge.com/widget/${CLOUDSPONGE_API_KEY}.js`;

/**
 * The Default Config used in old Dreambig.
 */
export const DEFAULT_CLOUDSPONGE_CONFIG: CloudspongeContactPickerOptions = {
  theme: "frost",
  sources: ["gmail", "yahoo", "windowslive", "csv"],
  localeData: {
    SELECT_CONTACTS: "Import",
    GET_CONTACTS: "Add Contacts",
    GET_CONTACTS_MOBILE: "Add",
    SEARCH_CONTACTS: "Search",
    SEARCH_SELECTED: "Search",
  },
  zIndex: 999999999999,
};

export type CloudspongeLocaleDataKeys =
  | "POWERED_BY"
  | "ADDRESS_BOOK"
  | "AUTHORIZATION"
  | "AUTHORIZATION_FOCUS"
  | "SIGN_IN"
  | "LOADING_CONTACTS"
  | "SEARCH_CONTACTS"
  | "SEARCH_SELECTED"
  | "NO_SELECTED_CONTACTS"
  | "GET_CONTACTS"
  | "GET_CONTACTS_MOBILE"
  | "SELECT_CONTACTS"
  | "SELECT_ALL"
  | "DESELECT_ALL"
  | "SELECT_SEARCH"
  | "DESELECT_SEARCH"
  | "REVIEW_SELECTED"
  | "BACK_TO_LIST"
  | "NO_SEARCH_RESULTS"
  | "MORE_MAILS"
  | "MORE_PHONES"
  | "DETAILS_TITLE"
  | "DETAILS_EMAIL"
  | "DETAILS_PHONE"
  | "DETAILS_ADDRESS"
  | "SIGN_IN_ACCOUNT"
  | "USERNAME"
  | "PASSWORD"
  | "ENTER_USERNAME"
  | "ENTER_PASSWORD"
  | "PRIVACY_NOTICE"
  | "PRIVACY_DISCLAIMER"
  | "IMPORT_CONTACTS"
  | "GET_CSV_CONTACTS"
  | "EXPORT_CSV"
  | "DRAG_FILES"
  | "DROP_FILES"
  | "CHOICE_ANOTHER"
  | "CLICK_HERE"
  | "NOT_SUPPORTED"
  | "SELECTION_LIMIT"
  | "DESELECT_CONFIRM"
  | "SUGGESTIONS"
  | "STORAGE_LOGGED_AS"
  | "STORAGE_LAST_UPDATE"
  | "STORAGE_ENABLED_STATUS"
  | "STORAGE_BT_UPDATE_NOW"
  | "STORAGE_BT_DISABLE_CACHE"
  | "STORAGE_DISABLED_STATUS"
  | "STORAGE_BT_ENABLE_CACHE"
  | "UNABLE_LOAD_CONTACTS"
  | "ERROR_258"
  | "ERROR_259"
  | "ERROR_262"
  | "ERROR_263"
  | "ERROR_263_ICLOUD"
  | "ERROR_264"
  | "ERROR_265"
  | "ERROR_266"
  | "ERROR_267"
  | "ERROR_514"
  | "ERROR_516"
  | "ERROR_518"
  | "ERROR_528"
  | "ERROR_544"
  | "ERROR_769"
  | "ERROR_770"
  | "ERROR_771"
  | "ERROR_772"
  | "ERROR_774"
  | "ERROR_775"
  | "ERROR_1025"
  | "ERROR_1026"
  | "ERROR_1027"
  | "ERROR_UNEXPECTED"
  | "ERROR_RESTART"
  | "ERROR_CLOSE"
  | "ERROR_10"
  | "ICLOUD_TWO_STEPS_VERIF";

export type CloudspongeContactSources =
  | "gmail"
  | "yahoo"
  | "office365"
  | "csv"
  | "aol"
  | "icloud"
  | "outlook"
  | "addressbook"
  | "mail_ru"
  | "uol"
  | "bol"
  | "terra"
  | "rediff"
  | "mail126"
  | "mail163"
  | "mail_yeah_net"
  | "gmx"
  | "qip_ru"
  | "sapo"
  | "mailcom"
  | "windowslive"
  | "yandex_ru";

export type CloudSpongeContactTemplates =
  | "address"
  | "birthday"
  | "companies"
  | "company"
  | "dob"
  | "email"
  | "emailsList"
  | "firstName"
  | "groups"
  | "homeAddress"
  | "jobTitle"
  | "lastName"
  | "name"
  | "otherAddress"
  | "phone"
  | "phonesList"
  | "primaryEmail"
  | "workAddress";

/**
 * Defined interface to represent init options for the Cloudsponge API since they do not have a Typescript package or NPM package.
 *
 * REFERENCE: https://www.cloudsponge.com/developer/contact-picker/options/
 */
export interface CloudspongeContactPickerOptions {
  alwaysShowCTA?: boolean;
  afterClosing?: string;
  afterLaunch?: Function;
  afterImport?: (source: string, success: boolean) => any;
  afterInit?: Function;
  afterSubmitContacts?: (
    contacts?: Record<string, any>,
    source?: string,
    owner?: Record<string, any>
  ) => any;
  beforeClosing?: Function;
  beforeDisplayContacts?: (
    contacts: Record<string, any>,
    source: string,
    owner: Record<string, any>
  ) => any;
  beforeGathering?: (source: string) => any;
  beforeImport?: (source: string, importId: number) => any;
  beforeLaunch?: Function;
  contactsDelimiter?: string;
  contactsTemplate?: CloudSpongeContactTemplates;
  css?: string;
  displayContactsColumns?: string[];
  displaySelectAllNone?: boolean;
  filter?: (contact: Record<string, any>) => boolean;
  ignoreMultipleEmails?: boolean;
  ignoreMultiplePhones?: boolean;
  include?: string[];
  initiallySelectedContacts?: boolean;
  inlineOauth?: boolean;
  lazyLoad?: boolean;
  locale?: "bg" | "cn" | "de" | "en" | "es" | "fr" | "it" | "ja" | "nl" | "pt";
  localeData?: {
    [key in CloudspongeLocaleDataKeys]?: string;
  };
  noTracking?: boolean;
  oauthCredentials?: string | string[];
  onlyGroupedContacts?: boolean;
  performEmailValidation?: boolean;
  rootNodeSelector?: string;
  selectAccount?: boolean;
  selectionLimit?: number;
  selectionLimitMessage?: string;
  onSelectionLimitReached?: (selectedCount: number, message: string) => any;
  skipContactsDisplay?: boolean;
  skipSourceMenu?: boolean;
  sortBy?: string;
  sources?: CloudspongeContactSources[];
  theme?: "neon" | "frost" | "inline" | "unresponsive-desktop";
  userToken?: string;
  zIndex?: number;
}

/**
 * Cloudsponge Custom Hook
 * @param {CloudspongeContactPickerOptions} config The config object we want to pass to Cloudsponge's init object
 *
 * REFERENCE: https://www.cloudsponge.com/developer/contact-picker/javascript-api/init/
 *
 * The optional dependencies variable is used to reinitalize any callback methods during initialization of the hook.
 * Without this, the callback will continually use the original callback function and its scoped variables everytime.
 */
const useCloudsponge = (
  config: CloudspongeContactPickerOptions,
  dependencies: React.DependencyList
) => {
  useEffect(() => {
    // Create script element and append the cloudsponge api url to it.
    const script = document.createElement("script");
    script.src = CLOUDSPONGE_API_URL;
    script.async = true;
    document.body.appendChild(script);

    // Initialize cloudsponge once the script is loaded.
    script.onload = () => {
      if ((window as any).cloudsponge) {
        (window as any).cloudsponge.init(config);
      }
    };

    // Cleanup document body once component unmounts.
    return () => {
      document.body.removeChild(script);
    };
  }, dependencies);

  return {
    /**
     * Launches the contact picker. This function does nothing if the contact picker has not yet completed initialization.
     * Useful for launching the contact picker on a page load or button click, or when more flexibility is required for starting the import.
     *
     * @param {CloudspongeContactSources} source The specific source (i.e Gmail, Yahoo, etc) we want to launch. If no source is passed, it will just display the default sources menu.
     *
     * REFERENCE: https://www.cloudsponge.com/developer/contact-picker/javascript-api/launch/
     */
    launch(source?: CloudspongeContactSources) {
      if ((window as any).cloudsponge) {
        (window as any).cloudsponge.launch(source);
      }
    },
  };
};

export default useCloudsponge;
