// Returns the first image from an array of images, accounting for various meta sources from DB.

import { generateCloudinaryUrl, autoFormatImgUrl } from "./";
import { Image } from "@dreambigger/shared/src/types";

const getCoverImage = (images?: Image[]) => {
  if (!images?.[0]) {
    return "";
  }
  if (images[0].meta?.src) {
    return autoFormatImgUrl(images[0].meta.src);
  }
  if (!images[0].imageVersion) {
    return "";
  }
  return generateCloudinaryUrl(images[0].imageVersion, images[0].imageId);
};

export default getCoverImage;
