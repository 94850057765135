import { Icon } from "../icons";
import {
  faHeart,
  faCoffeeTogo,
  faWaveform,
  faMoneyBillWave,
  faPenSquare,
  faWallet,
  faPersonCarry,
  faQuestionCircle,
  faInfoCircle,
  faCreditCard,
  faIdCard,
  faCaretRight,
  faImages,
} from "../icons/light";
import {
  faPencil,
  faLock,
  faAngleRight,
  faCalendarDay,
  faMapMarkedAlt,
  faDownload,
  faGlobeAmericas,
  faTrashAlt,
  faCheckCircle,
} from "../icons/solid";
import { IconLookup } from "@fortawesome/fontawesome-svg-core";

// Available Icons - Find additional icons to import at https://fontawesome.com/icons/
const getIcon = (iconName?: string, sizeNum: number = 1) => {
  // Overiding fortAwesome's icon type requirements. Options include "1-10x"
  const size: any = `${sizeNum}x`;

  switch (iconName) {
    case "coffee":
      return <Icon icon={faCoffeeTogo as IconLookup} size={size} />;
    case "heart":
      return <Icon icon={faHeart as IconLookup} size={size} />;
    case "activity":
      return <Icon icon={faWaveform as IconLookup} size={size} />;
    case "give":
      return <Icon icon={faMoneyBillWave as IconLookup} size={size} />;
    case "apply":
      return <Icon icon={faPenSquare as IconLookup} size={size} />;
    case "serve":
      return <Icon icon={faPersonCarry as IconLookup} size={size} />;
    case "wallet":
      return <Icon icon={faWallet as IconLookup} size={size} />;
    case "question":
      return <Icon icon={faQuestionCircle as IconLookup} size={size} />;
    case "info":
      return <Icon icon={faInfoCircle as IconLookup} size={size} />;
    case "card":
      return <Icon icon={faCreditCard as IconLookup} size={size} />;
    case "id":
      return <Icon icon={faIdCard as IconLookup} size={size} />;
    case "images":
      return <Icon icon={faImages as IconLookup} size={size} />;
    case "pencil":
      return <Icon icon={faPencil as IconLookup} size={size} />;
    case "lock":
      return <Icon icon={faLock as IconLookup} size={size} />;
    case "angleRight":
      return <Icon icon={faAngleRight as IconLookup} size={size} />;
    case "calendar":
      return <Icon icon={faCalendarDay as IconLookup} size={size} />;
    case "map":
      return <Icon icon={faMapMarkedAlt as IconLookup} size={size} />;
    case "download":
      return <Icon icon={faDownload as IconLookup} size={size} />;
    case "earth-americas":
      return <Icon icon={faGlobeAmericas as IconLookup} size={size} />;
    case "trash":
      return <Icon icon={faTrashAlt as IconLookup} size={size} />;
    case "checkmark":
      return <Icon icon={faCheckCircle as IconLookup} size={size} />;
    case "hidden":
      return <></>;
    default:
      return <Icon icon={faCaretRight as IconLookup} size={size} />;
  }
};

export default getIcon;
