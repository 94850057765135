import { fetcherWithRefreshToken } from "../api/api";
import { User } from "../types";
import useSWR, { SWRResponse } from "swr";
import { userPaths } from "../api/users";
import TokenHelper from "../utils/tokenHelper";
import { AxiosInstance } from "axios";

const useUser = (
  api: AxiosInstance,
  tokenHelper: TokenHelper,
  initialUser?: User
): [boolean, SWRResponse<User, any>] => {
  const token = tokenHelper.getRefreshToken();
  const res = useSWR<User>(
    () => (token ? userPaths.getMe() : null),
    (url) => fetcherWithRefreshToken(api, tokenHelper, url),
    {
      fallbackData: initialUser,
    }
  );

  const loading = token && !res.data && !res.error;
  return [!!loading, res];
};

export default useUser;
