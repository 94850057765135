import { useScript } from "./use-script";
import { Recaptcha } from "../types/recaptcha";

const RECAPTCHA_SCRIPT = "https://www.google.com/recaptcha/enterprise.js";
declare var grecaptcha: {
  enterprise: Recaptcha;
};

export interface RecaptchaHookMethods {
  execute(action: string): Promise<string>;
  load(): string;
}

export const useRecaptcha = (siteKey?: string): RecaptchaHookMethods => {
  return {
    //use to initiate recaptcha verification for an action
    //read more - https://cloud.google.com/recaptcha-enterprise/docs/instrument-web-pages
    execute(action: string): Promise<string> {
      return new Promise((resolve: (token: string) => void, reject: (reason?: string) => void) => {
        grecaptcha.enterprise.ready(() => {
          if(!siteKey) {
            reject("Recaptcha site key not set");
            return;
          }
          grecaptcha.enterprise
            .execute(siteKey, {action})
            .then(resolve);
        });
      })
    },
    //loads the recaptcha script
    load() {
      return useScript(`${RECAPTCHA_SCRIPT}?render=${siteKey}`);
    },
  };
};
