import { memo, useState } from "react";
import { Group, Typography, FacebookShareButton } from "../../";
import { getIcon } from "../../../utils";
import { CloseOutlined } from "@ant-design/icons";

const { Text } = Typography;

type bannerProps = {
  icon?: string;
  iconBackground?: string;
  text1?: string;
  text2?: string;
  textColor?: string;
  ctaText?: string;
  ctaLinkOrFunction?: string | Function;
  facebookShareEnabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  closable?: boolean;
  darkHoverText?: boolean;
  uiColor?: string;
  showIconOnMobile?: boolean;
};

const Banner = ({
  icon,
  iconBackground,
  text1,
  text2,
  textColor = "black",
  ctaText,
  ctaLinkOrFunction,
  facebookShareEnabled,
  className,
  style,
  closable,
  darkHoverText,
  showIconOnMobile,
  uiColor = "black",
}: bannerProps) => {
  const [bannerVisible, setBannerVisible] = useState(true);
  const [ctaBackground, setCtaBackground] = useState("transparent");
  const [closeBackground, setCloseBackground] = useState("transparent");
  const shareUrl = process?.browser ? window.location.href.split("?")[0] : "";

  // When banner has been hidden by user, no longer render it.
  if (!bannerVisible) {
    return <></>;
  }

  const handleCtaClick = () => {
    if (!ctaLinkOrFunction) {
      return;
    }
    // if passed in prop is a string, it is a url and should trigger a redirect
    if (typeof ctaLinkOrFunction === "string") {
      window.location.href = ctaLinkOrFunction;
      // else, the prop is a function, and the function should be executed
    } else {
      ctaLinkOrFunction();
    }
  };

  return (
    <Group
      xs="horizontal"
      className={`w-100 min-h-5 lg_min-h-10 pr-2 md_pr-6 br-1 justify-between ${className}`}
      style={style}
    >
      <Group xs="horizontal" className="justify-center">
        {/* ICON - Defaults to not displaying on mobile, but can be configured to override this. */}
        {icon && (
          <div
            className={`${
              !showIconOnMobile && "dn"
            } md_flex pv-2 ph-4 self-center`}
            style={{
              background: iconBackground || "transparent",
              color: uiColor,
            }}
          >
            <div className="mv-auto">{getIcon(icon, 2)}</div>
          </div>
        )}
        {/* BANNER TEXT - Can take in up two lines of text. The second line will resize at smaller screen sizes, like a caption. */}

        {/* COLUMN 1 - Text, facebook share, and (desktop only) cta-button */}
        <Group xl="horizontal" className="ml-2 pv-2">
          {/* Text 1 */}
          {text1 && (
            <p
              className="mr-2 f-4 lg_f-5 mv-auto xl_self-center"
              style={{ color: textColor }}
              dangerouslySetInnerHTML={{
                __html: text1,
              }}
            />
          )}
          {/* Text 2 */}
          {text2 && (
            <p
              className="mr-2 f-2 lg_f-5 xl_self-center mb-0"
              style={{ color: textColor }}
              dangerouslySetInnerHTML={{
                __html: text2,
              }}
            />
          )}
          {/* Facebook Share - Only Visible If Enabled */}
          {facebookShareEnabled && (
            <FacebookShareButton
              url={shareUrl}
              className="f-2 md_f-4 lg_f-5 fwb self-start xl_self-center tc min-w-15 underline pointer"
              style={{ color: uiColor }}
            >
              Share to Facebook
            </FacebookShareButton>
          )}
          {/* Mobile CTA Button - ONLY VISIBLE IF ALL RELEVANT PROPS HAVE BEEN PASSED IN */}
          {ctaText && ctaLinkOrFunction && (
            <button
              className={`self-start pointer mv-2 min-w-15 lg_dn ${
                darkHoverText ? "hlight-black" : "hlight-white"
              }`}
              style={{
                background: ctaBackground,
                border: `solid 2px ${uiColor}`,
                transition: "0.1s",
                color: uiColor,
              }}
              onClick={handleCtaClick}
              onMouseEnter={() => setCtaBackground(uiColor)}
              onMouseLeave={() => setCtaBackground("transparent")}
            >
              {ctaText}
            </button>
          )}
        </Group>
      </Group>

      {/* Column 2 - (desktop only) cta button and (always) close button */}
      <Group xs={"horizontal"}>
        {/* Desktop CTA Button - ONLY VISIBLE IF ALL RELEVANT PROPS HAVE BEEN PASSED IN */}
        {ctaText && ctaLinkOrFunction && (
          <button
            className={`self-center pointer min-w-15 dn lg_db ${
              darkHoverText ? "hlight-black" : "hlight-white"
            }`}
            style={{
              background: ctaBackground,
              border: `solid 2px ${uiColor}`,
              transition: "0.1s",
              color: uiColor,
            }}
            onClick={handleCtaClick}
            onMouseEnter={() => setCtaBackground(uiColor)}
            onMouseLeave={() => setCtaBackground("transparent")}
          >
            {ctaText}
          </button>
        )}
        {/* Close Banner Button - Only shows if "setVisible" function has been passed in. Requires container div for proper hover effect.*/}
        {closable && (
          <div className="flex flex_column items-center">
            <CloseOutlined
              onClick={() => {
                setBannerVisible(false);
              }}
              style={{ color: textColor, background: closeBackground }}
              className={`f-5 ml-2 md_ml-4 pa-1 pointer br-100 self-center ${
                darkHoverText ? "hlight-black" : "hlight-white"
              }`}
              // Hover effect - Not using css so that it can be styled by passed-in uiColor prop.
              onMouseEnter={() => setCloseBackground(uiColor)}
              onMouseLeave={() => setCloseBackground("transparent")}
            />
          </div>
        )}
      </Group>
    </Group>
  );
};

export default memo(Banner);
