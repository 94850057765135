import styles from "./nav-bar.module.scss";
import "./nav-bar.scss";
import { Skeleton, Spin } from "antd";
import cx from "classnames";
import { useBreakpoint } from "../../../hooks/use-breakpoint";
import {
  faBars,
  faTimes,
  faSearch,
  faAngleRight,
  faAngleDown,
  faHeart,
  faCoffeeTogo,
  faWaveform,
  faMoneyBillWave,
  faPenSquare,
  faWallet,
  faPersonCarry,
  faQuestionCircle,
  faInfoCircle,
  faCreditCard,
  faIdCard,
  faCaretRight,
} from "../../../icons/light";
import { faCaretDown } from "../../../icons/solid";
import { Icon } from "../../../icons";
import { useEffect, useMemo, useRef, useState } from "react";
import { animated, useTransition, UseTransitionProps } from "react-spring";
import useClickOutsideCallback from "../../../hooks/use-click-outside";
import {
  Divider,
  Avatar,
  Button,
  Group,
  Typography,
  Menu,
  AlgoliaAutoCompleteSearch,
} from "../..";
import { User } from "@dreambigger/shared/src/types";
import { generateCloudinaryUrl } from "@dreambigger/shared/src/utils";
import { useRouter } from "next/router";
import { IconLookup } from "@fortawesome/fontawesome-svg-core";

const { Text } = Typography;
const { SubMenu } = Menu;

const dropdownAnimationStyles = {
  from: {
    background: "white",
    opacity: 0,
    transform: "translate3D(0,-20px,0)",
    position: "absolute",
    width: "100%",
  },
  enter: {
    opacity: 1,
    transform: "translate3D(0,1,0)",
  },
  leave: { opacity: 0 },
} as UseTransitionProps;

type MenuClick = (info: {
  key: string;
  keyPath: string[];
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}) => void;

export interface MenuItem {
  key: string;
  name: string;
  isSelected?: boolean;
  onClick?: MenuClick;
  subItems?: SubItem[];
}

export interface SubItem {
  key: string;
  name: string;
  icon?: string;
  onClick?: MenuClick;
}
export interface LogoConfig {
  src?: string;
  onClick: React.MouseEventHandler<HTMLImageElement>;
}

export interface NavProps {
  menuItems?: MenuItem[];
  user?: User;
  logo?: LogoConfig;
  userMenuItems: SubItem[];
  showFunds: boolean;
  userLoading: boolean;
  hideSignIn: boolean;
  communityUrl?: string;
  signInCallback: React.MouseEventHandler<HTMLElement>;
  searchIndexName?: string;
  searchUrlPathname?: string;
  searchFilters?: string;
}

const Nav: React.FC<NavProps> = ({
  menuItems,
  user,
  userMenuItems,
  logo,
  showFunds,
  userLoading,
  hideSignIn,
  communityUrl,
  signInCallback,
  searchIndexName,
  searchUrlPathname,
  searchFilters,
}) => {
  const router = useRouter();
  const { isWiderThan, breakpoint } = useBreakpoint();
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const menuRef = useRef(null);
  const searchRef = useRef(null);

  // Closes the menu when a user clicks outside the menu.
  useClickOutsideCallback(menuRef, () => setMenuOpen(false));
  // Closes the menu when a user clicks outside the menu.
  useClickOutsideCallback(searchRef, () => setSearchOpen(false));

  // Close the menu on breakpoint change.
  useEffect(() => {
    handleCloseBothMenus();
  }, [breakpoint]);

  const handleOpenSearch = () => {
    setMenuOpen(false);
    setSearchOpen(true);
  };

  const handleOpenMenu = () => {
    setMenuOpen(true);
    setSearchOpen(false);
  };

  const handleCloseBothMenus = () => {
    setMenuOpen(false);
    setSearchOpen(false);
  };

  const isLargeScreen = isWiderThan("md");

  const menuTransitions = useTransition(menuOpen, dropdownAnimationStyles);
  const searchTransitions = useTransition(searchOpen, dropdownAnimationStyles);

  const isSignedIn = !!user;

  // Available Icons - Find additional icons to import at https://fontawesome.com/icons/
  const getIcon = (iconName: string) => {
    switch (iconName) {
      case "coffee":
        return <Icon icon={faCoffeeTogo as IconLookup} />;
      case "heart":
        return <Icon icon={faHeart as IconLookup} />;
      case "activity":
        return <Icon icon={faWaveform as IconLookup} />;
      case "give":
        return <Icon icon={faMoneyBillWave as IconLookup} />;
      case "apply":
        return <Icon icon={faPenSquare as IconLookup} />;
      case "serve":
        return <Icon icon={faPersonCarry as IconLookup} />;
      case "wallet":
        return <Icon icon={faWallet as IconLookup} />;
      case "question":
        return <Icon icon={faQuestionCircle as IconLookup} />;
      case "info":
        return <Icon icon={faInfoCircle as IconLookup} />;
      case "card":
        return <Icon icon={faCreditCard as IconLookup} />;
      case "id":
        return <Icon icon={faIdCard as IconLookup} />;
      default:
        return <Icon icon={faCaretRight as IconLookup} />;
    }
  };

  // MENU ITEMS COMPONENT
  const menuItemsComponent = useMemo(
    () =>
      menuItems &&
      menuItems.length > 0 &&
      menuItems.map((item) => {
        if (!item.subItems) {
          // Menu Items without Submenu Components ---------
          return (
            <Menu.Item
              className="no-select"
              key={item.key}
              onClick={item.onClick}
            >
              {item.name}
            </Menu.Item>
          );
        }

        const subItemTitle = (
          <span>
            {item.name + " "}
            {isLargeScreen && (
              <Icon
                icon={faCaretDown as IconLookup}
                className="relative ml-1 gray-7"
              />
            )}
          </span>
        );

        // Submenu Items ------------------------------------
        return (
          <>
            <SubMenu key={item.key} title={subItemTitle}>
              {/* For community board, always display activity */}
              {communityUrl && item.key === "more" && (
                <Menu.Item
                  className="no-select"
                  key="activity"
                  onClick={() => {
                    if (router && router.push) {
                      router.push(communityUrl.toString());
                    } else {
                      window.location.pathname = communityUrl.toString();
                    }
                  }}
                  icon={getIcon("activity")}
                >
                  Activity
                </Menu.Item>
              )}
              {item.subItems.map((subItem) => (
                <Menu.Item
                  className="no-select"
                  key={subItem.key}
                  onClick={subItem.onClick}
                  icon={getIcon(subItem.icon!)}
                >
                  {subItem.name}
                </Menu.Item>
              ))}
            </SubMenu>
          </>
        );
      }),
    [menuItems, isLargeScreen]
  );

  // SEARCH BAR COMPONENT ------------------------------------
  const search = useMemo(
    () =>
      // Only render a search if searchUrlPathname, searchIndexName, and organizationId have been passed in.
      searchUrlPathname &&
      searchIndexName && (
        <AlgoliaAutoCompleteSearch
          urlPathname={searchUrlPathname}
          className="nav-search"
          indexName={searchIndexName}
          filters={searchFilters}
          placeholder="Search"
          size={"large"}
        />
      ),
    [isLargeScreen, searchUrlPathname]
  );

  // AVAILABLE FUNDS / MONEY BUCKET COMPONENT -------------------
  const availableFundsComponent = showFunds &&
    user?.balanceToSpend !== undefined &&
    user?.balanceToSpend > -1 && (
      <>
        {isLargeScreen && (
          <Divider type="vertical" className="h-7 b-gray-4 mh-0" />
        )}
        <div className={isLargeScreen ? "tr ml-4" : "tl"}>
          <Text type="success" strong className="w-100 db">
            ${user.balanceToSpend}
          </Text>
          <Text className={cx(isLargeScreen ? "mt--1" : "", "f-1 db")}>
            Available Funds
          </Text>
        </div>
      </>
    );

  // USER AVATAR COMPONENT ------------------------------------
  const userInitials = (
    (user && user?.firstName[0] + user?.lastName[0]) ||
    ""
  ).toUpperCase();

  const userImg =
    user?.imageId &&
    // We don't want to use the default avatar image that is saved to users.
    user?.imageId !== "avatar" &&
    generateCloudinaryUrl(user.imageVersion!, user.imageId, { type: "avatar" });

  const userAvatar = user && (
    <Avatar src={userImg} size={40}>
      {!userImg && userInitials}
    </Avatar>
  );

  // USER DROPDOWN MENU COMPONENT  ----------------------------
  const userDropdown = (
    <SubMenu
      key="user-sub-menu"
      className="pa-0"
      disabled={userLoading}
      title={
        isLargeScreen ? (
          <Group spacing={1} className="items-center" xs="horizontal">
            {userAvatar}
            <Icon
              icon={faCaretDown as IconLookup}
              className="relative gray-7"
            />
          </Group>
        ) : userLoading ? (
          <Spin style={{ marginLeft: 1 }} />
        ) : (
          "My Account"
        )
      }
    >
      {userMenuItems.map((item) => (
        <Menu.Item className="no-select" key={item.key} onClick={item.onClick}>
          {item.name}
        </Menu.Item>
      ))}
    </SubMenu>
  );

  const selectedItems = useMemo(
    () =>
      menuItems
        ? menuItems.filter((item) => item.isSelected).map((item) => item.key)
        : [],
    [menuItems]
  );

  return (
    <div
      className={cx(
        styles.fullWidth,
        "w-100 sticky top-0 bg-white",
        "nav-wrapper"
      )}
      style={{ height: 75 }}
    >
      <nav
        className={cx(
          "flex items-center justify-between h-100",
          "dream-nav",
          "max-page-width",
          "default-wrapper-padding"
        )}
      >
        <Group xs="horizontal" className="items-center">
          {!logo?.src && (
            <Skeleton.Button active={true} style={{ height: 35, width: 150 }} />
          )}
          {logo?.src && (
            <img
              src={logo.src}
              className="mr-5"
              style={{ maxHeight: 50, maxWidth: 180, cursor: "pointer" }}
              alt="Logo"
              onClick={logo.onClick}
            />
          )}
          {isLargeScreen && (
            <Menu
              disabledOverflow
              mode="horizontal"
              selectedKeys={selectedItems}
            >
              {menuItemsComponent}
            </Menu>
          )}
        </Group>
        <Group xs="horizontal" spacing={5} className="flex items-center">
          {!isLargeScreen && (
            <>
              {searchOpen && (
                <Icon
                  className="f-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSearchOpen(false)}
                  icon={faTimes as IconLookup}
                  role="open search"
                />
              )}
              {!searchOpen && (
                <Icon
                  className="f-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenSearch()}
                  icon={faSearch as IconLookup}
                  role="close search"
                />
              )}
              {menuOpen && (
                <Icon
                  className="f-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => setMenuOpen(false)}
                  icon={faTimes as IconLookup}
                  role="close menu"
                />
              )}
              {!menuOpen && (
                <Icon
                  className="f-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenMenu()}
                  icon={faBars as IconLookup}
                  role="open menu"
                />
              )}
            </>
          )}

          {isLargeScreen && (
            <>
              {/* Search Bar */}
              {search}
              {userLoading ? (
                <Spin style={{ display: "flex" }} />
              ) : (
                <>
                  {isSignedIn && (
                    <>
                      {availableFundsComponent}
                      <Menu
                        disabledOverflow
                        mode="horizontal"
                        selectedKeys={selectedItems}
                      >
                        {userDropdown}
                      </Menu>
                    </>
                  )}
                  {!isSignedIn && !hideSignIn && (
                    <Button
                      size="large"
                      type="primary"
                      className="lightenPrimaryBkd"
                      onClick={signInCallback}
                    >
                      SIGN IN
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </Group>
      </nav>
      {menuTransitions(
        (transitionStyles, show) =>
          show && (
            <animated.div
              style={transitionStyles}
              ref={menuRef}
              className={styles.navDropdown}
            >
              {isSignedIn || userLoading ? (
                <>
                  <Group
                    xs="horizontal"
                    spacing={4}
                    className="ph-5 pv-4 items-center justify-between"
                  >
                    {userLoading ? (
                      <Spin />
                    ) : (
                      <>
                        {availableFundsComponent}
                        {userAvatar}
                      </>
                    )}
                  </Group>
                  <div className="mh-5">
                    <Divider className="mb-2 b-gray-4" />
                  </div>
                </>
              ) : (
                <div className="mt-2" />
              )}
              {menuItemsComponent && (
                <Menu
                  disabledOverflow
                  style={{ width: "100%" }}
                  mode="inline"
                  selectedKeys={selectedItems}
                  expandIcon={({ isOpen }) =>
                    isOpen ? (
                      <Icon icon={faAngleDown as IconLookup} />
                    ) : (
                      <Icon icon={faAngleRight as IconLookup} />
                    )
                  }
                >
                  {menuItemsComponent}
                  {isSignedIn && userDropdown}
                  {!isSignedIn && !hideSignIn && (
                    <div className="mh-5 mt-3 mb-4">
                      <Button
                        size="large"
                        type="primary"
                        className="w-100"
                        onClick={signInCallback}
                      >
                        SIGN IN
                      </Button>
                    </div>
                  )}
                </Menu>
              )}
            </animated.div>
          )
      )}

      {searchTransitions(
        (transitionStyles, show) =>
          show && (
            <animated.div style={transitionStyles} ref={searchRef}>
              <div className="bg-white pa-4">{search}</div>
            </animated.div>
          )
      )}
    </div>
  );
};

export default Nav;
