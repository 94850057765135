import { message } from "antd";
import React from "react";
import { useCallback } from "react";
import { Icon } from "../../../icons";
import { Button } from "../../atoms";
import { faLink } from "../../../icons/light";
import { IconLookup } from "@fortawesome/fontawesome-svg-core";

function fallbackCopyTextToClipboard(text: string) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}

export const CopyToClipboard = ({
  text,
  style,
  iconStyle,
  className,
  iconClassName,
  round = true,
  onClick,
}: {
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  className?: string;
  iconClassName?: string;
  round?: boolean;
}) => {
  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    // Call if defined.
    onClick?.(e);

    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      message.success("Copied to clipboard");

      return;
    }
    navigator.clipboard.writeText(text).then(function () {
      message.success("Copied to clipboard");
    });
  }, []);

  return (
    <Button
      aria-label="copy to clipboard"
      type="text"
      className={`${className} ${!round && "br-0"}`}
      onClick={handleClick}
      style={{
        ...style,
      }}
    >
      <Icon
        icon={faLink as IconLookup}
        className={iconClassName}
        style={{
          ...iconStyle,
        }}
      />
    </Button>
  );
};
