import React, { useState, useEffect } from "react";
import style from "./style.module.scss";

interface AnimatedProgressBarProps {
  height: number;
  progress: number;
}

const AnimatedProgressBar = ({
  height = 30,
  progress = 0,
}: AnimatedProgressBarProps) => {
  const [width, setWidth] = useState(0);
  const roundedProgress = Math.round(progress);

  // Animation effect that fires when a new progress amount is detected.
  useEffect(() => {
    if (width < roundedProgress && width < 100) {
      setTimeout(() => setWidth(width + 1), 20);
    }
    if (width > roundedProgress && width > 0) {
      setTimeout(() => setWidth(width - 1), 20);
    }
  }, [width, progress]);

  return (
    <div
      style={{
        height: `${height}px`,
        width: "100%",
        backgroundColor: "#e8f4dd",
        overflow: "hidden",
      }}
    >
      <div
        className={`${style.progressBar} ${style.progressBarSuccess} ${style.progressBarStriped} ${style.active}`}
        style={{ width: `${width}%`, height: "100%" }}
      ></div>
    </div>
  );
};

export default AnimatedProgressBar;
