import React, { useCallback } from "react";
import { CotributeLogo } from "../../../assets";
import { Icon } from "../../../icons";
import { Group, Typography } from "../../atoms";
import { faPhone, faEnvelope } from "../../../icons/light";
import cotributeDefaults from "@dreambigger/shared/src/assets/cotribute-defaults";
import { useSegment } from "@dreambigger/shared/src/hooks";
import { IconLookup } from "@fortawesome/fontawesome-svg-core";
import Image from "next/image";

const { Text, Link } = Typography;

export interface FooterProps {
  logo: string;
  name: string;
  homepage: string;
  aboutLink?: string;
  supportEmail?: string | null;
  supportPhone?: string | null;
  privacyLink?: string;
  termsLink?: string;
  cotributeSlug?: string;
  cotributeTermsLink?: string;
  cotributePrivacyLink?: string;
  hideFooter?: boolean;
  cotributeFooter?: {
    additionalLogosMaxHeight?: string; // Ex: "42px"
    additionalLogos?: [
      {
        src: string;
        url?: string;
      }
    ];
  };
}

const Footer: React.FC<FooterProps> = ({
  logo,
  name,
  homepage,
  aboutLink,
  supportEmail,
  supportPhone,
  privacyLink,
  termsLink,
  cotributeSlug,
  cotributeTermsLink,
  cotributePrivacyLink,
  hideFooter,
  cotributeFooter,
}: FooterProps) => {
  const segment = useSegment();
  const goToCotributeHomePage = useCallback(
    () =>
      window.open(
        cotributeDefaults.cotributeHomepage(cotributeSlug || ""),
        "_blank"
      ),
    []
  );

  return (
    <div className="dream-footer bg-gray-3">
      {/* ORGANIZATION FOOTER ROW - Can optionally be hidden by configuring hideOrgFooter to be true */}
      {!hideFooter && (
        <div className="max-page-width pa-5">
          {/* Logo */}
          <Group
            sm="horizontal"
            spacing={5}
            className="items-center sm_items-start"
          >
            <img
              src={logo}
              className="max-h-7"
              style={{ maxHeight: 50, cursor: "pointer" }}
              alt="Logo"
              onClick={() => window.open(homepage, "_blank")}
            />

            <Group
              xs="horizontal"
              className="w-100 justify-between mt-2 sm_max-w-100 md_ph-5 md_ml--1"
              style={{ maxWidth: 425 }}
            >
              {/* LEFT COLUMN */}
              <Group spacing={4} className="min-w-50 pr-2 items-start">
                {/* About */}
                {aboutLink && (
                  <Link
                    onClick={() => {
                      segment.track({
                        action: "Link Click",
                        label: `About ${name.toUpperCase()}`,
                      });
                    }}
                    strong
                    style={{ fontSize: 11 }}
                    href={aboutLink}
                    target="_blank"
                    className="gray-7"
                  >
                    ABOUT {name.toUpperCase()}
                  </Link>
                )}
                {!aboutLink && (
                  <Text strong style={{ fontSize: 11 }} className="gray-7">
                    {name.toUpperCase()}
                  </Text>
                )}

                {/* Privacy Policy */}
                {privacyLink && (
                  <Link
                    onClick={() => {
                      segment.track({
                        action: "Link Click",
                        label: "Privacy Policy",
                      });
                    }}
                    className="f-1 gray-7"
                    href={privacyLink}
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                )}

                {/* Terms of Use */}
                {termsLink && (
                  <Link
                    onClick={() => {
                      segment.track({
                        action: "Link Click",
                        label: "Terms of Use",
                      });
                    }}
                    className="f-1 gray-7"
                    href={termsLink}
                    target="_blank"
                  >
                    Terms of Use
                  </Link>
                )}
              </Group>

              {/* RIGHT COLUMN */}
              <Group
                spacing={4}
                className="min-w-50 pl-2 sm_ml-auto sm_min-w-auto items-start"
              >
                {(supportPhone || supportEmail) && (
                  <Text strong style={{ fontSize: 11 }} className="gray-7">
                    SUPPORT
                  </Text>
                )}
                {/* Support Phone */}
                {supportPhone && (
                  <Link
                    onClick={() => {
                      segment.track({
                        action: "Link Click",
                        label: "Support Phone",
                      });
                    }}
                    className="f-1 flex gray-7"
                    href={"tel:" + supportPhone}
                    target="_blank"
                  >
                    <Icon icon={faPhone as IconLookup} className="f-3 mr-2" />
                    {supportPhone}
                  </Link>
                )}
                {/* Support Email */}
                {supportEmail && (
                  <Link
                    onClick={() => {
                      segment.track({
                        action: "Link Click",
                        label: "Support Email",
                      });
                    }}
                    className="f-1 flex gray-7"
                    href={"mailto:" + supportEmail}
                    target="_blank"
                  >
                    <Icon
                      icon={faEnvelope as IconLookup}
                      className="f-3 mr-2"
                    />
                    {supportEmail}
                  </Link>
                )}
              </Group>
            </Group>
          </Group>
        </div>
      )}
      {/* COTRIBUTE FOOTER ROW */}
      <div className="bg-gray-4 w-100 pv-4 md_pv-2">
        <Group
          md="horizontal"
          spacing={3}
          className="default-wrapper-padding items-center md_flex-row-reverse md_justify-between"
        >
          <Group xs="horizontal" spacing={2}>
            <Text
              className="gray-7 fwb"
              style={{ fontSize: 9, letterSpacing: 1, marginTop: 2 }}
            >
              EMPOWERED BY
            </Text>
            <Image
              className="h-3"
              style={{ cursor: "pointer" }}
              width={81}
              height={12}
              src={CotributeLogo}
              alt="Cotribute logo"
              onClick={goToCotributeHomePage}
            />
          </Group>
          <Group
            xs="horizontal"
            className="items-center"
            wrap
            spacing={4}
            style={{ padding: "2px 0" }}
          >
            <Text className="f-1 gray-7">
              © {new Date().getFullYear()} Cotribute. All rights reserved.
            </Text>
            <Link
              onClick={() => {
                segment.track({
                  action: "Link Click",
                  label: "Privacy Policy",
                });
              }}
              className="f-1 gray-7"
              href={cotributePrivacyLink || cotributeDefaults.privacyPolicy}
              target="_blank"
            >
              Privacy Policy
            </Link>
            <Link
              onClick={() => {
                segment.track({ action: "Link Click", label: "Terms of Use" });
              }}
              className="f-1 gray-7"
              href={cotributeTermsLink || cotributeDefaults.terms}
              target="_blank"
            >
              Terms of Use
            </Link>
            {cotributeFooter?.additionalLogos &&
              cotributeFooter?.additionalLogos.map((additionalLogo, index) =>
                additionalLogo.url ? (
                  <Link href={additionalLogo.url}>
                    <img
                      src={additionalLogo.src}
                      style={{
                        maxHeight:
                          cotributeFooter?.additionalLogosMaxHeight || "24px",
                      }}
                      key={`${additionalLogo.src}/${index}`}
                    />
                  </Link>
                ) : (
                  <img
                    src={additionalLogo.src}
                    style={{
                      maxHeight:
                        cotributeFooter?.additionalLogosMaxHeight || "24px",
                    }}
                    key={`${additionalLogo.src}/${index}`}
                  />
                )
              )}
          </Group>
        </Group>
      </div>
    </div>
  );
};

export default Footer;
