import { memo, ReactNode } from "react";
import { BreakpointProvider, DefaultThemeProvider } from "../../..";

const DefaultErrorFrame = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <DefaultThemeProvider>
        <BreakpointProvider>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {children}
              </div>
            </div>
          </div>
        </BreakpointProvider>
      </DefaultThemeProvider>
    </>
  );
};

export default memo(DefaultErrorFrame);
