/**
 * Util functions to help make calls to both Mixpanel and Segment.
 */

import {
  MixpanelHookMethods,
  MIXPANEL_SUPER_PROPERTIES,
} from "../hooks/use-mixpanel";
import { SegmentHookMethods } from "../hooks";

/**
 * CARD_SELECTED
 *  - Tracks an event when a user clicks a specific entity card in the community.
 * PAGE_VIEW
 *  - Event that records a page view for the user.
 * LIKE
 *  - Tracks when a user clicks on a "Like" icon for a specific entity card.
 * SHARE
 *  - Tracks the share icon clicked by the user in the ShareBar component.
 */
export enum InspireAnalyticEvents {
  CARD_SELECTED = "Card Selected",
  LIKE = "Like",
  PAGE_VIEW = "Page View",
  SHARE = "share",
  RSVP_START = "RSVP - Start",
  CTA_CLICK = "CTA Click",
  THANK_YOU_BUTTON_CLICK = "Thank You Button Click",
  INVITE_CLICK = "Invite-Flow Click",
  INVITE_STEP_CHANGE = "Invite-Flow Step Change",
}

/**
 * Get the "Page Type" property to send to Mixpanel/Segment based on the Next.js route
 */
const getPageType = (pageRoute: string) => {
  switch (pageRoute) {
    case "/community/[communitySlug]/communities":
      return "Community Sub-Communities Page";
    case "/community/[communitySlug]/detail":
      return "Community Detail Page";
    case "/community/[communitySlug]/events":
      return "Community Events Page";
    case "/community/[communitySlug]/stories":
      return "Community Stories Page";
    case "/events/[eventId]/detail":
      return "Event Detail Page";
    case "/events/[eventId]/edit":
      return "Event Edit Page";
    case "/stories/[storyId]":
      return "Story Detail Page";
    case "/actions/invites/event/[eventId]":
      return "Event Invite Flow";
    default:
      return "";
  }
};

export const InspireAnalyticsHelper = {
  getPageType,
};

/**
 * Make Identify calls using the Mixpanel/Segment APIs.
 */
const identify = ({
  id,
  mixpanel,
  segment,
}: {
  id: string;
  mixpanel?: MixpanelHookMethods;
  segment?: SegmentHookMethods;
}) => {
  if (mixpanel) {
    mixpanel.identify(id);
  }

  if (segment) {
    segment.identify({ userId: id });
  }

  return;
};

/**
 * Make Track calls using the Mixpanel/Segment APIs.
 */
const track = ({
  eventName,
  properties,
  mixpanel,
  segment,
}: {
  eventName: string;
  properties: Record<string, any>;
  mixpanel?: MixpanelHookMethods;
  segment?: SegmentHookMethods;
}) => {
  let mixpanelSuperProperties = {};

  // Slight delay so all libraries can initialize in time. (for example when user authenticates via hash so mixpanel/segment identify them in time)
  setTimeout(() => {
    if (mixpanel) {
      mixpanel.track(eventName, properties);

      // Get all mixpanel super properties from the current mixpanel instance so we can also send them to segment (or any other library).
      mixpanelSuperProperties = mixpanel.getSuperProperties(
        MIXPANEL_SUPER_PROPERTIES
      );
    }

    if (segment) {
      segment.track({
        action: "",
        label: eventName,
        properties: {
          ...mixpanelSuperProperties,
          ...properties,
        },
      });
    }
  }, 2000);

  return;
};

/**
 * Clear cookies + local storage when a user logs out.
 */
const reset = ({
  mixpanel,
  segment,
}: {
  mixpanel?: MixpanelHookMethods;
  segment?: SegmentHookMethods;
}) => {
  if (mixpanel) {
    mixpanel.reset();
  }

  if (segment) {
    segment.reset();
  }

  return;
};

export default { identify, track, reset };
