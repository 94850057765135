import { useAuthToken, useSegment } from "@dreambigger/shared/src/hooks";
import { LocalStorage } from "@dreambigger/shared/src/utils";
import { useCallback, useMemo } from "react";
import { keyByLoginModeHash } from "../constants/auth";

export default function useAuthHandler(
  financialInstitutionId?: string,
  trackingReference?: Record<string, string | undefined>
) {
  const segment = useSegment();
  const tokenHelper = useAuthToken(financialInstitutionId);
  const localStorage = useMemo(
    () => new LocalStorage(financialInstitutionId),
    [financialInstitutionId]
  );

  const successHandler = useCallback(
    (jwt: string, segmentEventLabel: string) => {
      // Save token and userId to local storage
      tokenHelper.setJwtToken(jwt);

      const userAttributes = tokenHelper.decodeJWT(jwt)?.user?.attributes;
      // can't think of why this wouldn't be, but TS don't know that
      if (userAttributes?.loginId) {
        const keyByLoginMode =
          userAttributes?.loginIdType === keyByLoginModeHash.email
            ? keyByLoginModeHash.email
            : keyByLoginModeHash.sms;

        localStorage.setItem(keyByLoginMode, userAttributes?.loginId);

        // Update user id on segment
        // Include traits sent before auth
        // Segment does not include traits sent before auth if user id / email changes
        const user = tokenHelper.decodeJWT(jwt)?.user;
        segment.identify({
          userId: user?.id,
          traits: {
            [keyByLoginMode]: userAttributes?.loginId,
            financialInstitutionId: financialInstitutionId,
            mostRecentFlowId: financialInstitutionId,
            ...trackingReference,
          },
        });
        segment.group({
          groupId: financialInstitutionId || "",
          traits: {
            ...trackingReference,
          },
        });

        segment.track({
          action: "Authentication",
          label: segmentEventLabel,
          properties: {
            [keyByLoginMode]: userAttributes?.loginId,
            flowId: financialInstitutionId,
          },
        });
      }
    },
    [tokenHelper, financialInstitutionId, segment]
  );

  return { successHandler };
}
