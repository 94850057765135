//************************************************************************ /
// CIRCLES IMAGE
// Image cropped in a circle with optional decorative accent circles.
// Used in Acquire for landing page template in the "circles" theme
//************************************************************************ /
import React, { CSSProperties } from "react";

export interface CirclesImageProps {
  src?: string;
  style?: CSSProperties;
  className?: string;
  accentColor1?: string;
  accentColor2?: string;
}

const CirclesImage = ({
  src,
  style,
  className,
  accentColor1,
  accentColor2,
}: CirclesImageProps) => {
  return (
    <>
      {src && (
        <div className={`${className} relative`} style={style}>
          <div
            style={{
              paddingBottom: "107%",
              position: "absolute",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                paddingBottom: "100%",
                position: "absolute",
                overflow: "hidden",
                borderRadius: "50%",
              }}
            >
              <img
                src={src}
                style={{
                  height: "100%",
                  position: "absolute",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            {accentColor1 && (
              <div
                style={{
                  backgroundColor: accentColor1,
                  width: "30%",
                  position: "absolute",
                  top: "0",
                  right: "6.4%",
                  borderRadius: "50%",
                  paddingBottom: "30%",
                }}
              ></div>
            )}
            {accentColor2 && (
              <div
                style={{
                  backgroundColor: accentColor2,
                  position: "absolute",
                  width: "21%",
                  paddingBottom: "21%",
                  bottom: "0%",
                  left: "16%",
                  borderRadius: "50%",
                }}
              ></div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CirclesImage;
