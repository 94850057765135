export default {
  name: "Cotribute",
  title: "Tap here to continue",
  description: "Powered by Cotribute",
  image:
    "https://res.cloudinary.com/cotribute/image/upload/v1584483963/tap-here-to-continue_kl1rn0.jpg",
  coverImage:
    "https://res.cloudinary.com/cotribute/image/upload/v1535643194/background-case-study_zydhbo.png",
  logo: "https://res.cloudinary.com/cotribute/image/upload/v1652206856/cotribute-logos-2022/Cotribute_Dark_Trimmed.png",
  terms: "https://www.cotribute.com/company/terms-of-use",
  privacyPolicy: "https://www.cotribute.com/company/privacy-policy",
  supportPhone: "800.790.8421",
  supportEmail: "support@cotribute.com",
  tabIconIco: "https://cotribute.s3.amazonaws.com/p/images/favicon.ico?v=2",
  primaryColor: "#52c1b2",
  cotributeHomepage: (orgName: string) =>
    `https://www.cotribute.com/?utm_source=externalevent&utm_medium=footer-icon&utm_campaign=${orgName}`,
  pinnedIcon:
    "https://res.cloudinary.com/cotribute/image/upload/v1580919516/p/pin.png",
  cloudspongeIcons: [
    {
      client: "gmail",
      src: "https://res.cloudinary.com/cotribute/image/upload/w_130,h_50/v1566927217/imaginary/ThankYouNotes/share%20buttons/gmail.png",
    },
    {
      client: "yahoo",
      src: "https://res.cloudinary.com/cotribute/image/upload/w_130,h_50/v1566927217/imaginary/ThankYouNotes/share%20buttons/yahoo.png",
    },
    {
      client: "windowslive",
      src: "https://res.cloudinary.com/cotribute/image/upload/w_130,h_50/v1566927217/imaginary/ThankYouNotes/share%20buttons/outlook.png",
    },
    {
      client: "csv",
      src: "https://res.cloudinary.com/cotribute/image/upload/w_130,h_50/v1566927217/imaginary/ThankYouNotes/share%20buttons/csv.png",
    },
  ],
};
